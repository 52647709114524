import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getBondByCompanyCsv (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      const url = path + '/api/export/bondByCompany/' + payload.companyHash + '/' + payload.status
      return new Promise((resolve, reject) => {
        axios.get(url, {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        })
          .then(response => {
            resolve(response.data)
            const filename = 'exported_bond.csv'
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getBondByCompanyPdf (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      const url = path + '/api/export/bondByCompanyPdf/' +
        payload.companyHash + '/' + payload.status
      return new Promise((resolve, reject) => {
        axios.get(url, {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        })
          .then(response => {
            resolve(response.data)
            const filename = 'exported_bond.pdf'
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
