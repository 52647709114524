<template>
  <v-container
  class="px-7"
  fluid
  id="bond-creation"
  pt-20
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="error"
      :timeout="6000"
      >
      {{ errorMessage }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{ name: 'bond-management' }" class="back-btn" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
        <CompanyInfo class="text-center" :company="company" :noDefault="true" />
        <h1 class="text-center" v-if="!isComplementary">{{ $t('add_new_bond') }}</h1>
        <h1 class="text-center" v-else>{{ $t('create_complementary_bond') }}</h1>
      </v-col>
    </v-row>
    <div justify="center" align="center" v-if="areCompaniesLoading || isBondFetchLoading">
      <v-skeleton-loader
        class="mx-auto mt-3"
        max-width="100%"
        type="article"
        elevation="4"
      ></v-skeleton-loader>
    </div>
    <v-row>
      <v-col cols="12" sm="6">
        <BondForm
          v-if="!(areCompaniesLoading || isBondFetchLoading)"
          :bondDetail="bondDetails"
          :isLoading="isLoading"
          :isUpdating="false"
          :companies="companies"
          @validate="validate"
          @updateFile="setFileBase64"
          @reloadCompanies="reloadCompanies"
        />
      </v-col>
      <v-col cols="12" sm="6" class="no-padding">
        <PdfViewer
          :files="files"
          :filesType="filesType"
          v-if="!(areCompaniesLoading || isBondFetchLoading)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PdfViewer from './PdfViewer'
import BondForm from './BondForm'
import CompanyInfo from '../CompanyInfo'
export default {
  components: {
    BondForm, PdfViewer, CompanyInfo
  },
  data () {
    return {
      isLoading: false,
      snackbar: false,
      companies: null,
      areCompaniesLoading: false,
      bondDetails: null,
      isBondFetchLoading: false,
      fileBase64: {
        '001': null,
        '002': null,
        '003': null
      },
      serverFileBase64: {
        '001': null,
        '002': null,
        '003': null
      }
    }
  },
  computed: {
    errorMessage () {
      return this.$t('an_error_occured')
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    isComplementary () {
      return this.$route.name === 'new-bond-complementary'
    },
    files () {
      const filesTypes = ['001', '002', '003']
      const files = []
      for (let i = 0; i < filesTypes.length; ++i) {
        if (this.fileBase64[filesTypes[i]]) {
          files.push(this.fileBase64[filesTypes[i]])
        }
      }
      return files
    },
    filesType () {
      const filesTypes = ['001', '002', '003']
      const files = []
      for (let i = 0; i < filesTypes.length; ++i) {
        if (this.fileBase64[filesTypes[i]]) {
          files.push(filesTypes[i])
        }
      }
      return files
    },
    company () {
      const companies = this.$store.getters.getCompanies
      const companyHash = this.$store.getters.getCurrentCompanyHash
      if (companies) {
        for (let i = 0; i < companies.length; ++i) {
          if (companies[i].hash === companyHash) {
            return companies[i]
          }
        }
      }
      return null
    }
  },
  created () {
    if (this.isSuperAdmin && !this.isComplementary) {
      this.fetchCompanies()
    }
    if (this.isComplementary) {
      this.fetchBondDetail()
    }
  },
  methods: {
    fetchCompanies () {
      this.areCompaniesLoading = true
      this.$store.dispatch('fetchCompaniesForForms')
        .then(response => {
          this.companies = response
          this.areCompaniesLoading = false
        })
        .catch(() => {
          this.areCompaniesLoading = false
        })
    },
    reloadCompanies () {
      this.$store.dispatch('fetchCompaniesForForms')
        .then(response => {
          this.companies = response
        })
    },
    async fetchBondDetail () {
      try {
        this.isBondFetchLoading = true
        this.bondDetails = await this.$store.dispatch('fetchBond', this.$route.params.bondHash)
        this.bondDetails = this.clearAmounts(this.bondDetails)
        let edocResponse, reader, type
        for (let i = 0; i < this.bondDetails.edocs.length; ++i) {
          edocResponse = await this.$store.dispatch('getFile', { hash: this.bondDetails.edocs[i].hash })
          type = this.bondDetails.edocs[i].type
          reader = new window.FileReader()
          reader.readAsDataURL(edocResponse)
          reader.onloadend = () => {
            this.fileBase64[type] = reader.result
            this.serverFileBase64[type] = reader.result
          }
        }
        this.isBondFetchLoading = false
      } catch (error) {
        this.isBondFetchLoading = false
      }
    },
    clearAmounts (bondDetails) {
      bondDetails.bond.montant_devise = 0
      bondDetails.bond.montant_initial = 0
      bondDetails.bond.montant_engage = 0
      bondDetails.bond.montant_droit_garde = 0
      bondDetails.bond.montant_travaux = 0
      return bondDetails
    },
    validate (form) {
      if (form.valid) {
        if (this.isComplementary) {
          form.complementaryBondHash = this.$route.params.bondHash
          form.isComplementary = true
        }
        const payload = {
          ...form,
          companyHash: this.getCompanyHash(form)
        }
        this.isLoading = true
        this.$store.dispatch('createBond', payload)
          .then((response) => {
            const message = this.$t('bond_sucessfully_created') + ' R' + response.data.id
            this.$store.commit('setSuccessAlert', message)
            this.isLoading = false
            this.$router.push({ name: 'bond-management' })
          })
          .catch(() => {
            this.isLoading = false
            this.snackbar = true
          })
      }
    },
    getCompanyHash (form) {
      if (this.isSuperAdmin) {
        if (this.isComplementary) {
          return this.$route.params.companyHash
        }
        return form.selectedCompanyHash
      }
      return this.$store.getters.getCurrentCompanyHash
    },
    readFileAsync (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    async setFileBase64 (file, fileType) {
      if (!file) {
        this.fileBase64[fileType] = null
        if (this.serverFileBase64[fileType]) {
          this.fileBase64[fileType] = this.serverFileBase64[fileType]
        }
        return
      }
      this.fileBase64[fileType] = await this.readFileAsync(file)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
