<template>
  <v-container
  class="px-7"
  fluid
  id="home"
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="6000"
      >
      {{ successAlert }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{ name: 'home' }" class="back-btn mb-5" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="justify-start tableau_company elevation-4 pa-4"
    >
      <v-col cols="12" class="text-left">
        <div v-if="users">
          <div id="tools_company" class="d-flex justify-space-between align-center pb-3">
            <h1>{{ $t('collaborator_management') }} <span class="number_company pl-4" v-if="!isSuperAdmin">{{ company.name }}</span></h1>
            <v-btn
              :to="{ name: 'new-admin-user' }"
              class="align-self-center"
              color="#AB650D"
              dark
              large
              >
              {{ $t('new') }}
            </v-btn>
          </div>
          <Datatable :users="users" :isLoading="isLoading" @reload="initialize"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from './UsersDatatable'
export default {
  name: 'users-list',
  components: {
    Datatable
  },
  data () {
    return {
      hasError: false,
      isLoading: true,
      errorMessage: null,
      successAlert: null,
      snackbar: false,
      users: []
    }
  },
  computed: {
    company () {
      const companies = this.$store.getters.getCompanies
      const companyHash = this.$store.getters.getCurrentCompanyHash
      for (let i = 0; i < companies.length; ++i) {
        if (companies[i].hash === companyHash) {
          return companies[i]
        }
      }
      return null
    },
    getCurrentCompanyHash () {
      return this.$store.getters.getCurrentCompanyHash
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.manageSnackbar()
      if (this.isSuperAdmin) {
        this.loadAllUsers()
      } else {
        this.loadUsersFromCompany()
      }
    },
    loadUsersFromCompany () {
      this.$store.dispatch('fetchUsersFromCompany', this.getCurrentCompanyHash)
        .then(response => {
          this.users = response
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    loadAllUsers () {
      this.$store.dispatch('fetchAllUsers')
        .then(response => {
          this.users = response
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    manageSnackbar () {
      if (this.$store.getters.getSuccessAlert) {
        this.snackbar = true
        this.successAlert = this.$store.getters.getSuccessAlert
        this.$store.commit('resetSuccessAlert')
      }
    }
  }
}
</script>
<style scoped>
</style>
