<template>
  <div id="bond-datatable" class="pa-5">
      <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="6000"
      >
      {{ successAlert }}
    </v-snackbar>
    <div id="select_box" class="d-flex justify-space-between align-start">
      <v-text-field
          v-model="search"
          :label="$t('search_for_an_edoc')"
          single-line
          hide-details
          style="max-width:350px"
          class="ml-3"
          color="#AB650D"
        ></v-text-field>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :search="search"
      fixed-header
      class="table-compa"
      >
      <template v-slot:item.is_read="{ item }">
        <v-icon v-if="item.is_read" @click="downloadItem(item)">
          mdi-email-open-outline
        </v-icon>
        <v-icon v-else @click="downloadItem(item)">
          mdi-email-receive
        </v-icon>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ $t('doc' + item.type) }}
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn text icon color="#AB650D" dark>
          <v-icon @click="downloadItem(item)">mdi-download</v-icon>
        </v-btn>
        <v-btn text icon color="#AB650D" dark v-if="!isReadOnlyUser">
          <v-icon @click="softDelete(item)">mdi-delete</v-icon>
        </v-btn>
      </template>
      <v-alert slot="no-results" :value="true" type="error">
        {{ $t('no_results_for') }} "{{ search }}"
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Datatable',
  components: {
  },
  props: ['edocs', 'companies'],
  data () {
    return {
      search: null,
      snackbar: false
    }
  },
  filters: {
  },
  computed: {
    headers () {
      return [
        { text: this.$t('status'), align: 'center', value: 'is_read' },
        { text: this.$t('date'), value: 'created_at' },
        { text: this.$t('companies'), value: 'companyName' },
        { text: this.$t('edoc_type'), value: 'type' },
        { text: this.$t('edoc'), value: 'name' },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ]
    },
    filteredItems () {
      if (!this.edocs) {
        return []
      }
      return this.edocs
    },
    successAlert () {
      return this.$t('edoc_successfully_added')
    },
    isReadOnlyUser () {
      return this.$store.getters.isReadOnlyUser
    }
  },
  methods: {
    openSnackbar () {
      this.snackbar = true
    },
    downloadItem (item) {
      this.$store.dispatch('downloadFile', item)
      this.$store.dispatch('setEdocAsRead', item.hash)
      this.setEdocAsReadFront(item)
    },
    setEdocAsReadFront (item) {
      item.is_read = true
    },
    refresh () {
      this.$emit('reload')
    },
    softDelete (item) {
      const shouldDelete = confirm(this.$t('confirm_edoc_delete'))
      if (!shouldDelete) {
        return
      }
      this.$store.dispatch('softDeleteEdoc', item.hash)
        .then(() => {
          this.$emit('reload')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table-header th{
    background:#e6e6e6 !important;
  }
</style>
