<template>
  <v-container
  class="px-7"
  fluid
  id="company-update"
  pt-20
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="error"
      :timeout="6000"
      >
      {{ errorMessage }}
    </v-snackbar>
    <div justify="center" align="center">
      <v-progress-circular
        :size="70"
        :width="4"
        color="#4a4a30"
        indeterminate
        v-if="isCompanyFetchLoading"
        justify="center"
        align="center"
      ></v-progress-circular>
    </div>
    <div v-if="!isCompanyFetchLoading && companyDetails">
      <v-row>
        <v-col cols="12">
          <v-btn :to="{ name: 'companies-list' }" class="back-btn" color="#AB650D" dark>
            <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
          </v-btn>
          <h1 class="text-center mb-5">{{ $t('update_new_company') }}</h1>
          <hr class="mb-3">
        </v-col>
      </v-row>
      <template>
        <v-tabs v-model="tab" vertical color="#AB650D" background-color="#fbfbfb">
          <v-tab key="1">{{ $t('tab_company_info') }}</v-tab>
          <v-tab key="2">{{ $t('tab_company_contact') }}</v-tab>
          <v-tab key="3">{{ $t('tab_company_adresse') }}</v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item key="1">
              <CompanyForm :form="form" :isLoading="isUpdateLoading" :isUpdating="true" @validate="validate" />
            </v-tab-item>
            <v-tab-item key="2">
              <CompanyContact
                v-if="companyDetails"
                :contacts="companyDetails.contacts"
                :company="companyDetails"
                @refresh="loadCompanyDetails"
              />
            </v-tab-item>
            <v-tab-item key="3">
              <CompanyAddress
                v-if="companyDetails"
                :addresses="companyDetails.addresses"
                :company="companyDetails"
                @refresh="loadCompanyDetails"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </template>
    </div>
  </v-container>
</template>

<script>
import CompanyForm from './CompanyForm'
import CompanyContact from './CompanyContact'
import CompanyAddress from './CompanyAddress'
export default {
  components: {
    CompanyForm,
    CompanyContact,
    CompanyAddress
  },
  data () {
    return {
      companyDetails: null,
      form: {
        id: null,
        name: '',
        language: '',
        vat_number: '',
        bank_account: '',
        nr_amo: null,
        is_momentary: false,
        valid: false
      },
      isCompanyFetchLoading: true,
      isUpdateLoading: false,
      snackbar: false,
      tab: null
    }
  },
  computed: {
    errorMessage () {
      return this.$t('an_error_occured')
    }
  },
  created () {
    this.loadCompanyDetails()
  },
  methods: {
    validate () {
      if (this.form.valid) {
        const payload = {
          ...this.form,
          companyHash: this.companyDetails.hash
        }
        this.isUpdateLoading = true
        this.$store.dispatch('updateCompany', payload)
          .then(() => {
            const message = this.$t('company_sucessfully_updated')
            this.$store.commit('setSuccessAlert', message)
            this.isUpdateLoading = false
            this.$router.push({ name: 'companies-list' })
          })
          .catch(() => {
            this.isUpdateLoading = false
            this.snackbar = true
          })
      }
    },
    fillCompanyForm (companyDetail) {
      this.form.name = companyDetail.name
      this.form.vat_number = companyDetail.vat_number
      this.form.bank_account = companyDetail.bank_account
      this.form.language = companyDetail.language
      this.form.is_momentary = companyDetail.is_momentary
      this.form.nr_amo = companyDetail.nr_amo
      this.form.id = companyDetail.id
    },
    loadCompanyDetails () {
      this.$store.dispatch('fetchCompany', this.$route.params.hash)
        .then(response => {
          this.isCompanyFetchLoading = false
          this.companyDetails = response
          this.fillCompanyForm(this.companyDetails)
        })
        .catch(() => {
          this.isCompanyFetchLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
