<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn
          class="select-lang"
          v-on="on"
        >
          {{ language | toUpperCase}}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="changeLanguage(item)"
        >
          <v-list-item-title>{{ item | toUpperCase }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: ['fr', 'nl'],
      language: null
    }
  },
  created () {
    const language = localStorage.getItem('language')
    this.setLanguage(language)
  },
  methods: {
    changeLanguage (value) {
      this.language = value
      this.setLanguage(this.language)
    },
    setLanguage (language) {
      if (language && ['nl', 'fr'].includes(language)) {
        this.language = language
        this.$root.$i18n.locale = language
        this.$vuetify.lang.current = language
        localStorage.setItem('language', language)
      } else {
        this.language = 'fr'
        this.$vuetify.lang.current = 'fr'
        this.$root.$i18n.locale = 'fr'
      }
    }
  },
  filters: {
    toUpperCase (value) {
      return value.toUpperCase()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
