<template>
  <v-dialog
      v-model="dialog"
      width="600"
      content-class="dialog-position"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          v-if="companyHash"
          color="primary"
          v-on="on"
          class="mb-4"
        >
          {{ $t('manage_beneficiaires') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
          >
          {{ $t(form && form.id ? 'edit_beneficiaire' : 'new_beneficiaire') }}
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab key="1" @change="checkIfNeedToBePopulated">{{ $t(form && form.id ? 'edit_beneficiaire' : 'new_beneficiaire') }}</v-tab>
            <v-tab key="2">{{ $t('manage_beneficiaires') }}</v-tab>
          </v-tabs>
        </v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item key="1">
            <v-form
              v-model="form.valid"
              @submit.prevent="validate"
              id="beneficiaire-form"
              ref="form"
            >
            <v-card-text>
                <v-textarea
                  color="#AB650D"
                  v-model="form.name"
                  :rules="nameRules"
                  :label="$t('name')"
                  :counter="400"
                  required
                ></v-textarea>
                <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                <v-text-field
                  color="#AB650D"
                  v-model="form.street"
                  :rules="streetRules"
                  :label="$t('street')"
                  required
                ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                <v-text-field
                  color="#AB650D"
                  v-model="form.number"
                  :rules="numberRules"
                  :label="$t('number')"
                  required
                ></v-text-field>
                    </v-col>
                    </v-row>
                    <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                <v-text-field
                  color="#AB650D"
                  v-model="form.post_code"
                  :rules="postCodeRules"
                  :label="$t('post_code')"
                  required
                ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="8"
                    >
                <v-text-field
                  color="#AB650D"
                  v-model="form.city"
                  :rules="cityRules"
                  :label="$t('city')"
                  required
                ></v-text-field>
                    </v-col>
                    </v-row>
                <v-select
                  color="#AB650D"
                  item-color="brown"
                  v-model="form.country"
                  :rules="[v => !!v || $t('country_required')]"
                  :items="countries"
                  :label="$t('country')"
                  required
                ></v-select>
                <v-text-field
                  color="#AB650D"
                  v-model="form.email"
                  :rules="emailRules"
                  :label="$t('email')"
                  required
                ></v-text-field>
                <v-text-field
                  color="#AB650D"
                  v-model="form.nr_id"
                  :rules="nrIdRules"
                  :label="$t('nr_id')"
                  required
                ></v-text-field>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <div justify="center" align="center" width="100%">
                <v-btn
                  type="submit"
                  :disabled="!form.valid || !companyHash"
                  color="success"
                  class="mr-4 mt-8"
                  :loading="isLoading"
                  x-large
                >
                  {{ $t(form && form.id ? 'update' : 'validate') }}
                </v-btn>
                </div>
              </v-card-actions>
            </v-form>
          </v-tab-item>
          <v-tab-item key="2">
            <v-text-field v-model="searchedBenef" :label="$t('filter_by_name')" class="px-4"></v-text-field>
            <v-simple-table class="mt-6">
              <template v-slot:default>
                <tbody>
                  <tr v-for="beneficiaire in filteredBeneficiaires" :key="beneficiaire.id">
                    <td><b>{{ beneficiaire.name }}</b></td>
                    <td v-if="!beneficiaire.is_lock"><v-icon @click="fillEditForm(beneficiaire, true)">mdi-pencil</v-icon></td>
                    <td v-else><v-icon @click="fillEditForm(beneficiaire)">mdi-pencil-plus</v-icon></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
</template>

<script>
import filterBeneficiaires from '../../filters/filterBeneficiaires'
export default {
  props: ['companyHash', 'beneficiaires', 'isUpdating', 'type_2', 'value', 'previousBeneficiaires'],
  data () {
    return {
      form: {
        valid: false,
        street: null,
        post_code: null,
        number: null,
        city: null,
        name: null,
        country: 'BE',
        id: null,
        email: null,
        nr_id: null
      },
      isLoading: false,
      menu: null,
      tab: null,
      searchedBenef: null
    }
  },
  created () {
    if (!this.isUpdating) {
      this.tab = 1
    }
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    countries () {
      return [
        { value: 'BE', text: this.$t('BE') },
        { value: 'FR', text: this.$t('FR') },
        { value: 'NL', text: this.$t('NL') },
        { value: 'DE', text: this.$t('DE') },
        { value: 'GB', text: this.$t('GB') },
        { value: 'LU', text: this.$t('LU') }
      ]
    },
    nameRules () {
      return [
        v => !!v || this.$t('name_required'),
        v => (v && v.length < 401) || this.$t('name_too_long')
      ]
    },
    streetRules () {
      return [
        v => !!v || this.$t('street_required'),
        v => (v && v.length < 41) || this.$t('street_too_long'),
        v => (v && v.length > 4) || this.$t('street_too_short')
      ]
    },
    postCodeRules () {
      return [
        v => !!v || this.$t('post_code_required'),
        v => (v && v.length < 16) || this.$t('post_code_too_long'),
        v => (v && v.length > 1) || this.$t('post_code_too_short')
      ]
    },
    numberRules () {
      return [
        v => !!v || this.$t('number_required'),
        v => (v && v.length < 7) || this.$t('number_too_long')
      ]
    },
    cityRules () {
      return [
        v => !!v || this.$t('city_required'),
        v => (v && v.length < 31) || this.$t('city_too_long'),
        v => (v && v.length > 1) || this.$t('city_too_short')
      ]
    },
    emailRules () {
      return [
        v => !!v || this.$t('email_required'),
        v => (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v)) || this.$t('email_must_be_valid')
      ]
    },
    isNrIdMandatory () {
      return this.type_2 === 1 || this.type_2 === 2
    },
    nrIdRules () {
      return [
        v => !!v || this.$t('nr_id_required'),
        v => (!!v || !this.isNrIdMandatory) || this.$t('nr_id_mandatory'),
        v => (!v || this.isNrIdValid) || this.$t('nr_id_invalid')
      ]
    },
    filteredBeneficiaires () {
      const beneficiaires = filterBeneficiaires(this.beneficiaires)
      if (!this.searchedBenef || this.searchedBenef.length < 2) {
        return beneficiaires
      }
      const filteredBeneficiaires = []
      let benefName
      const searched = this.searchedBenef.toLowerCase()
      for (let i = 0; i < beneficiaires.length; ++i) {
        benefName = beneficiaires[i].name.toLowerCase()
        if (benefName.includes(searched)) {
          filteredBeneficiaires.push(beneficiaires[i])
        }
      }
      return filteredBeneficiaires
    },
    keyPageChangeActivated () {
      return this.$store.getters.getKeyPageChangeActivated
    },
    isNrIdValid () {
      return this.isBelgianNationalRegistrationNumberValid(this.form.nr_id) ||
        this.isCompanyNumberValid(this.form.nr_id) || this.form.nr_id === 'NA'
    }
  },
  methods: {
    validate () {
      if (!this.form.id) {
        this.createNewBeneficiaire()
      } else {
        this.updateBeneficiaire()
      }
    },
    createNewBeneficiaire () {
      if (this.form.valid) {
        this.form.company = {
          hash: this.companyHash
        }
        this.isLoading = true
        this.$store.dispatch('createBeneficiaire', this.form)
          .then(response => {
            this.isLoading = false
            this.dialog = false
            this.$emit('created', response.id)
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    fillEditForm (beneficiaire, canEdit = false) {
      if (canEdit) {
        this.form.id = beneficiaire.id
      } else {
        this.form.id = null
      }
      this.form.street = beneficiaire.street
      this.form.post_code = beneficiaire.post_code
      this.form.city = beneficiaire.city
      this.form.country = beneficiaire.country
      this.form.name = beneficiaire.name
      this.form.number = beneficiaire.number
      this.form.email = beneficiaire.email
      this.form.nr_id = beneficiaire.nr_id
      this.tab = 0
      setTimeout(() => {
        this.$refs.form.validate()
      }, 0)
    },
    updateBeneficiaire () {
      if (this.form.valid) {
        this.isLoading = true
        this.$store.dispatch('editBeneficiaire', this.form)
          .then(response => {
            this.isLoading = false
            this.dialog = false
            this.$emit('created', response.id)
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    resetForm () {
      this.form = {
        valid: false,
        street: null,
        post_code: null,
        number: null,
        city: null,
        other: null,
        country: null,
        id: null,
        nr_id: null,
        email: null
      }
    },
    isBelgianNationalRegistrationNumberValid (rawNumber) {
      if (rawNumber.length !== 11) {
        return false
      }
      const firstPart = rawNumber.slice(0, 9)
      const verificationPart = +rawNumber.slice(9, 11)
      const remaining = +firstPart % 97
      const calculatedVerificationNumber = Math.abs(remaining - 97)
      if (calculatedVerificationNumber === verificationPart) {
        return true
      }
      const firstPartAfter2000 = '2' + firstPart
      const remainingAfter2000 = +firstPartAfter2000 % 97
      const calculatedVerificationNumberAfter2000 = Math.abs(remainingAfter2000 - 97)
      if (calculatedVerificationNumberAfter2000 === verificationPart) {
        return true
      }
      return false
    },
    isCompanyNumberValid (number) {
      if (number.length !== 10) {
        return false
      }
      let last2Numbers = number.substring(number.length - 2)
      number = number.substring(0, number.length - 2)
      const rest = number % 97
      const verification = parseInt(97 - rest)
      last2Numbers = parseInt(last2Numbers)
      return last2Numbers === verification
    },
    checkIfNeedToBePopulated () {
      this.form.street = null
      this.form.post_code = null
      this.form.city = null
      this.form.country = 'BE'
      this.form.number = null
      if (this.type_2 === 2 && this.previousBeneficiaires) {
        const firstBeneficiaire = this.beneficiaires.filter(beneficiaire => beneficiaire.id === this.previousBeneficiaires[0])
        this.form.street = firstBeneficiaire[0].street
        this.form.post_code = firstBeneficiaire[0].post_code
        this.form.city = firstBeneficiaire[0].city
        this.form.country = firstBeneficiaire[0].country
        this.form.number = firstBeneficiaire[0].number
      }
    }
  },
  watch: {
    editingBeneficiaire (newValue) {
      if (!newValue) {
        return
      }
      this.form.street = newValue.street
      this.form.post_code = newValue.post_code
      this.form.number = newValue.number
      this.form.city = newValue.city
      this.form.other = newValue.other
      this.form.country = newValue.country
      this.form.id = newValue.id
      this.form.email = newValue.email
      this.form.nr_id = newValue.nr_id
      this.dialog = true
      this.$emit('resetEditingBeneficiaire')
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  display: initial !important;
}
</style>
