export default {
  state: {
    successAlert: null,
    errorAlert: null
  },
  getters: {
    getSuccessAlert (state) {
      return state.successAlert
    },
    getErrorAlert (state) {
      return state.errorAlert
    }
  },
  mutations: {
    setSuccessAlert (state, payload) {
      state.successAlert = payload
    },
    setErrorAlert (state, payload) {
      state.errorAlert = payload
    },
    resetSuccessAlert (state) {
      state.successAlert = null
    },
    resetErrorAlert (state) {
      state.errorAlert = null
    }
  },
  actions: {
  }
}
