import Vue from 'vue'
import Router from 'vue-router'
import EdocList from '../components/Edoc/List'
import Login from '../components/Login'
import LostPassword from '../components/LostPassword'
import NewPassword from '../components/NewPassword'
import BondDetail from '../components/Bonds/Detail'
import BondCreation from '../components/Bonds/Creation'
import BondEdition from '../components/Bonds/Edition'
import BondProcessing from '../components/Bonds/Processing'
import AdminUserList from '../components/Admin/UserList'
import AdminUserCreation from '../components/Admin/UserCreation'
import AdminUserEdition from '../components/Admin/UserEdition'
import CompaniesList from '../components/SuperAdmin/CompanyList'
import CompanyCreation from '../components/SuperAdmin/CompanyCreation'
import CompanyEdition from '../components/SuperAdmin/CompanyEdition'
import BondManagement from '../components/Home'
import LiberationTable from '../components/Bonds/LiberationTable'

import AuthGuard from './auth-guard'
import NotAuthGuard from './notauth-guard'
import AdminGuard from './admin-guard'
import SuperAdminGuard from './superadmin-guard'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: EdocList,
      beforeEnter: AuthGuard
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: NotAuthGuard
    },
    {
      path: '/lost-password',
      name: 'lost-password',
      component: LostPassword,
      beforeEnter: NotAuthGuard
    },
    {
      path: '/new-password/:lostToken',
      name: 'new-password',
      component: NewPassword,
      beforeEnter: NotAuthGuard
    },
    {
      path: '/bond/new',
      name: 'new-bond',
      component: BondCreation,
      beforeEnter: AuthGuard
    },
    {
      path: '/bond/new/:bondHash/:companyHash',
      name: 'new-bond-complementary',
      component: BondCreation,
      beforeEnter: SuperAdminGuard
    },
    {
      path: '/bond/:hash',
      name: 'bond-detail',
      component: BondDetail,
      beforeEnter: AuthGuard
    },
    {
      path: '/bond/edit/:hash',
      name: 'edit-bond',
      component: BondEdition,
      beforeEnter: AuthGuard
    },
    {
      path: '/bond/process/:hash',
      name: 'process-bond',
      component: BondProcessing,
      beforeEnter: SuperAdminGuard
    },
    {
      path: '/admin/users',
      name: 'admin-users-list',
      component: AdminUserList,
      beforeEnter: AdminGuard
    },
    {
      path: '/admin/users/new',
      name: 'new-admin-user',
      component: AdminUserCreation,
      beforeEnter: AdminGuard
    },
    {
      path: '/admin/users/edit/:hash',
      name: 'edit-admin-user',
      component: AdminUserEdition,
      beforeEnter: AdminGuard
    },
    {
      path: '/master/companies',
      name: 'companies-list',
      component: CompaniesList,
      beforeEnter: SuperAdminGuard
    },
    {
      path: '/master/companies/new',
      name: 'new-company',
      component: CompanyCreation,
      beforeEnter: SuperAdminGuard
    },
    {
      path: '/master/companies/edit/:hash',
      name: 'edit-company',
      component: CompanyEdition,
      beforeEnter: SuperAdminGuard
    },
    {
      path: '/bond',
      name: 'bond-management',
      component: BondManagement,
      beforeEnter: AuthGuard
    },
    {
      path: '/liberations/new',
      name: 'liberation-table',
      component: LiberationTable,
      beforeEnter: SuperAdminGuard
    }
  ]
})
