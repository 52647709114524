<template>
  <v-container
  class="px-7"
  fluid
  id="user-update"
  pt-20
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="error"
      :timeout="6000"
      >
      {{ errorMessage }}
    </v-snackbar>
    <div justify="center" align="center">
      <v-progress-circular
        :size="70"
        :width="4"
        color="#4a4a30"
        indeterminate
        v-if="isUserFetchLoading"
        justify="center"
        align="center"
      ></v-progress-circular>
    </div>
    <div v-if="!isUserFetchLoading && userDetails">
      <v-row>
        <v-col cols="12">
          <v-btn :to="{ name: 'admin-users-list' }" class="back-btn" color="#AB650D" dark>
            <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
          </v-btn>
          <h1 class="text-center mb-5">{{ $t('update_new_user') }}</h1>
        </v-col>
      </v-row>
      <UserForm :form="form" :isLoading="isUpdateLoading" :isUpdating="true" @validate="validate" />
    </div>
  </v-container>
</template>

<script>
import UserForm from './UserForm'
export default {
  components: {
    UserForm
  },
  data () {
    return {
      userDetails: null,
      form: {
        email: '',
        name: '',
        roles: 'ROLE_USER',
        status: 1,
        companies: [],
        valid: false
      },
      isUserFetchLoading: true,
      isUpdateLoading: false,
      snackbar: false
    }
  },
  computed: {
    errorMessage () {
      return this.$t('an_error_occured')
    }
  },
  created () {
    this.$store.dispatch('fetchUser', this.$route.params.hash)
      .then(response => {
        this.isUserFetchLoading = false
        this.userDetails = response
        this.fillUserForm(this.userDetails)
      })
      .catch(() => {
        this.isUserFetchLoading = false
      })
  },
  methods: {
    validate () {
      if (this.form.valid) {
        const payload = {
          ...this.form,
          userHash: this.userDetails.hash
        }
        if (payload.email === this.userDetails.email) {
          delete payload.email
        }
        payload.roles = [payload.roles]
        this.isUpdateLoading = true
        this.$store.dispatch('updateUser', payload)
          .then(() => {
            const message = this.$t('user_sucessfully_updated')
            this.$store.commit('setSuccessAlert', message)
            this.isUpdateLoading = false
            this.$router.push({ name: 'admin-users-list' })
          })
          .catch(() => {
            this.isUpdateLoading = false
            this.snackbar = true
          })
      }
    },
    fillUserForm (userDetail) {
      this.form.email = userDetail.email
      this.form.roles = userDetail.roles[0]
      this.form.status = userDetail.status
      this.form.name = userDetail.name
      if (userDetail.companies) {
        this.form.companies = userDetail.companies.map(item => item.hash)
      } else {
        this.form.companies = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
