var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5",attrs:{"id":"bond-datatable"}},[_c('v-snackbar',{attrs:{"bottom":true,"color":"success","timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$t('password_generation_sent'))+" ")]),_c('v-data-table',{staticClass:"table-compa",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"fixed-header":"","loading":_vm.isLoading},scopedSlots:_vm._u([{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.roles))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.displayStatus(item.status)))+" ")]}},{key:"item.companies",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("singleLineCompanies")(item.companies))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.editSingleItem(item)}}},[_vm._v("mdi-pencil-outline")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.sendPasswordChangeRequest(item)}}},[_vm._v("mdi-lock-reset")])],1),_c('v-btn',{attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.deleteSingleItem(item)}}},[_vm._v("mdi-delete")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }