<template>
  <div id="user-form">
    <v-row class="justify-center">
      <v-col cols="12" :sm="momentaryModal ? 12 : 6">
        <div class="details-fiche " justify="center" align="center">
          <v-form
            v-model="form.valid"
            @submit.prevent="validate"
            id="user-form"
            >
            <v-checkbox
              v-if="!isUpdating && !momentaryModal"
              color="#AB650D"
              v-model="form.is_momentary"
              :label="$t('is_momentary')"
              required
            ></v-checkbox>
            <v-autocomplete
              v-if="form.is_momentary && !isUpdating"
              v-model="form.linked_companies"
              :items="selectableCompanies"
              :loading="areCompaniesLoading"
              :rules="companiesRules"
              chips
              small-chips
              :label="$t('companies')"
              multiple
              item-value="hash"
              item-text="name"
            ></v-autocomplete>
            <v-text-field
              color="#AB650D"
              v-model="form.name"
              :rules="nameRules"
              :label="$t('name')"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              color="#AB650D"
              v-model="form.vat_number"
              :label="$t('vat_number')"
              @keyup="formatVatNumber(form.vat_number)"
              required
              autocomplete="off"
            ></v-text-field>
            <v-text-field
              color="#AB650D"
              v-model="form.bank_account"
              :label="$t('bank_account')"
              @keyup="formatBankNumber(form.bank_account)"
              required
              autocomplete="off"
            ></v-text-field>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.language"
              :rules="[v => !!v || $t('langue_needed')]"
              :items="languages"
              :label="$t('langue')"
              required
            ></v-select>
            <v-text-field
              v-if="form.nr_amo"
              color="#AB650D"
              item-color="brown"
              v-model="form.nr_amo"
              :label="$t('nr_amo')"
              readonly
              disabled
            >
            </v-text-field>
            <v-alert
              type="info"
              text
              v-if="!form.id"
              >
              {{ $t('nr_amo_will_be_generated') }}
            </v-alert>
            <v-alert
              type="warning"
              text
              v-if="form.vat_number && !isVatNumberValid(form.vat_number)"
              >
              {{ $t('vat_number_invalid') }}
            </v-alert>
            <v-alert
              type="warning"
              text
              v-if="form.bank_account && !isBankAccountValid(form.bank_account)"
              >
              {{ $t('bank_account_invalid') }}
            </v-alert>
            <v-btn
              type="submit"
              :disabled="!form.valid"
              color="success"
              class="mr-4 mt-8"
              :loading="isLoading"
              x-large
            >
              {{ $t('validate') }}
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['form', 'isLoading', 'isUpdating', 'momentaryModal'],
  data () {
    return {
      languages: ['FR', 'NL'],
      areCompaniesLoading: false,
      companies: []
    }
  },
  computed: {
    roles () {
      return [
        { value: 'ROLE_USER', text: this.$t('ROLE_USER') },
        { value: 'ROLE_USER_READONLY', text: this.$t('ROLE_USER_READONLY') },
        { value: 'ROLE_ADMIN', text: this.$t('ROLE_ADMIN') }
      ]
    },
    status () {
      return [
        { value: 1, text: this.$t('active') },
        { value: 0, text: this.$t('inactive') }
      ]
    },
    companiesRules () {
      return [
        v => !!v || this.$t('at_least_1_companies_needed'),
        v => v.length > 0 || this.$t('at_least_1_companies_needed')
      ]
    },
    nameRules () {
      return [
        v => !!v || this.$t('name_required'),
        v => (v && v.length < 96) || this.$t('name_too_long'),
        v => (v && v.length > 6) || this.$t('name_too_short')
      ]
    },
    selectableCompanies () {
      const companies = this.companies
      const selectableCompanies = []
      for (let i = 0; i < companies.length; ++i) {
        if (!companies[i].isMomentary) {
          selectableCompanies.push(companies[i])
        }
      }
      return selectableCompanies
    }
  },
  created () {
    this.loadCompanies()
  },
  methods: {
    validate () {
      this.$emit('validate')
    },
    loadCompanies () {
      this.areCompaniesLoading = true
      this.$store.dispatch('fetchCompaniesForForms')
        .then(response => {
          this.companies = response
          this.areCompaniesLoading = false
        })
        .catch(() => {
          this.areCompaniesLoading = false
        })
    },
    formatVatNumber (value) {
      let newValue = value
      newValue = newValue.replace(/ |-|\./g, '')
      newValue = newValue.toUpperCase()
      this.form.vat_number = newValue
    },
    formatBankNumber (value) {
      let newValue = value
      newValue = newValue.replace(/ |-|\./g, '')
      newValue = newValue.toUpperCase()
      this.form.bank_account = newValue
    },
    isVatNumberValid (number) {
      if (number.length !== 11 && number.length !== 12) {
        return false
      }
      number = number.substring(2)
      let last2Numbers = number.substring(number.length - 2)
      number = number.substring(0, number.length - 2)
      const rest = number % 97
      const verification = parseInt(97 - rest)
      last2Numbers = parseInt(last2Numbers)
      return last2Numbers === verification
    },
    isBankAccountValid (number) {
      const first4Char = number.slice(0, 4)
      number = number.replace(first4Char, '')
      number += first4Char
      number = this.replaceLettersByNumber(number)
      const module10first = number.substring(0, 10)
      const module10end = number.substring(10)
      const newNumber = (module10first % 97) + '' + module10end
      return (newNumber % 97) === 1
    },
    replaceLettersByNumber (number) {
      let newNumber = ''
      for (let i = 0; i < number.length; ++i) {
        if (number.charCodeAt(i) >= 65 && number.charCodeAt(i) <= 90) {
          newNumber += number.charCodeAt(i) - 55
        } else {
          newNumber += number[i]
        }
      }
      return newNumber
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>

</style>
