var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5",attrs:{"id":"bond-datatable"}},[_c('v-snackbar',{attrs:{"bottom":true,"color":"success","timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.successAlert)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-start",attrs:{"id":"select_box"}},[_c('v-autocomplete',{staticClass:"ml-3",staticStyle:{"max-width":"350px"},attrs:{"loading":_vm.areCompaniesLoading,"items":_vm.companies,"color":"#AB650D","item-color":"brown","item-value":"hash","item-text":"name","clearable":""},on:{"change":_vm.changeCompany},model:{value:(_vm.params.companyHash),callback:function ($$v) {_vm.$set(_vm.params, "companyHash", $$v)},expression:"params.companyHash"}}),_c('v-text-field',{staticClass:"ml-3",staticStyle:{"max-width":"350px"},attrs:{"label":_vm.$t('search_for_an_edoc'),"single-line":"","hide-details":"","color":"#AB650D"},on:{"input":_vm.paramsChanged},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}}),_c('EdocModal',{attrs:{"companies":_vm.companies},on:{"refresh":_vm.refresh,"snackbar":_vm.openSnackbar}})],1),_c('v-data-table',{staticClass:"table-compa",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"fixed-header":"","server-items-length":parseInt(_vm.total,10),"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }},on:{"update:page":_vm.changePage,"update:options":_vm.changeOptions},scopedSlots:_vm._u([{key:"item.is_read_fideris",fn:function(ref){
    var item = ref.item;
return [(item.is_read_fideris)?_c('v-icon',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v(" mdi-email-open-outline ")]):_c('v-icon',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v(" mdi-email-receive ")])]}},{key:"item.company",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.company.name)+" ")]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.type))+" ")]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v("mdi-download")])],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"type":"error"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t('no_results_for'))+" \""+_vm._s(_vm.params.search)+"\" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }