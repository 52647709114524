import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
    edocs: null
  },
  getters: {
    getEdocs (state) {
      return state.edocs
    }
  },
  mutations: {
    setEdocs (state, payload) {
      state.edocs = payload
    }
  },
  actions: {
    addEdoc (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      const formData = obtainFormData(payload)
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/edocs', formData, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchEdocsFromCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/edocs/from-company/' + payload, request)
          .then(response => {
            const edocs = addPlainCompanyName(response.data)
            context.commit('setEdocs', edocs)
            context.commit('setIsCompanyLoading', false)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchAdminEdocs (context, payload) {
      const params = {
        page: payload.page,
        limit: payload.limit,
        search: payload.search,
        company_hash: payload.companyHash
      }
      context.commit('setIsCompanyLoading', true)
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          params
        }
        axios.get(path + '/api/edocs/master/', request)
          .then(response => {
            context.commit('setEdocs', response.data)
            context.commit('setIsCompanyLoading', false)
            resolve()
          })
          .catch((error) => {
            context.commit('setIsCompanyLoading', false)
            reject(error)
          })
      })
    },
    fetchAllEdocs (context) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/edocs/', request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setEdocAsRead (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      const param = {
        edocHash: payload
      }
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/edocs/set-read', param, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    softDeleteEdoc (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/edocs/soft-delete/' + payload, {}, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}

function obtainFormData (payload) {
  const formData = new FormData()
  if (payload.file) {
    formData.append('edoc', payload.file)
  }
  formData.append('companyHash', payload.company)
  formData.append('bondHash', payload.bonds)
  return formData
}

function addPlainCompanyName (edocs) {
  for (let i = 0; i < edocs.length; ++i) {
    if (edocs[i].company) {
      edocs[i].companyName = edocs[i].company.name
    }
  }
  return edocs
}
