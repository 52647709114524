export default {
  state: {
    page: 0,
    maxPage: 0,
    keyPageChangeActivated: true
  },
  getters: {
    getPage (state) {
      return state.page
    },
    getMaxPage (state) {
      return state.maxPage
    },
    getKeyPageChangeActivated (state) {
      return state.keyPageChangeActivated
    }
  },
  mutations: {
    setMaxPage (state, payload) {
      if (typeof payload === 'number') {
        state.maxPage = payload
      }
    },
    nextPage (state) {
      if (!state.keyPageChangeActivated) {
        return
      }
      if (state.maxPage <= state.page) {
        return
      }
      state.page = state.page + 1
    },
    previousPage (state) {
      if (!state.keyPageChangeActivated) {
        return
      }
      if (state.page <= 1) {
        return
      }
      state.page = state.page - 1
    },
    initPage (state) {
      state.page = 1
    },
    navigateToPage (state, payload) {
      if (payload > 0 && payload <= state.maxPage) {
        state.page = payload
      }
    },
    toggleKeyPageChangeActivated (state) {
      state.keyPageChangeActivated = !state.keyPageChangeActivated
    }
  },
  actions: {
  }
}
