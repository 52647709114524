import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import translations from './assets/i18n'
import router from './router/index.js'
import { store } from './store/index.js'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import formatDate from './filters/formatDate'
import formatPrice from './filters/formatPrice'
import singleLineCompanies from './filters/singleLineCompanies'

Vue.config.productionTip = false

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'nl',
  messages: translations
})

Vue.filter('formatDate', formatDate)
Vue.filter('formatPrice', formatPrice)
Vue.filter('singleLineCompanies', singleLineCompanies)

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

if (window.Cypress) {
  // only available during E2E tests
  window.app = app
}
