<template>
  <v-container
  class="px-7"
  fluid
  id="home"
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="6000"
      >
      {{ successAlert }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{ name: 'home' }" class="back-btn mb-5" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="justify-start tableau_company elevation-4 pa-4"
    >
      <v-col class="text-left">
        <div v-if="companies">
          <div id="tools_company" class="d-flex justify-space-between align-center pb-3">
            <h1>{{ $t('companies_list') }}</h1>
          </div>
          <Datatable :companies="companies" :isLoading="isLoading" @reload="initialize"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from './CompaniesDatatable'
export default {
  name: 'companies-list',
  components: {
    Datatable
  },
  data () {
    return {
      hasError: false,
      isLoading: true,
      errorMessage: null,
      successAlert: null,
      snackbar: false,
      companies: []
    }
  },
  computed: {
  },
  created () {
    this.initialize()
  },
  methods: {
    initialize () {
      this.manageSnackbar()
      this.loadCompanies()
    },
    loadCompanies () {
      this.$store.dispatch('fetchCompanies')
        .then(response => {
          this.companies = response
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    manageSnackbar () {
      if (this.$store.getters.getSuccessAlert) {
        this.snackbar = true
        this.successAlert = this.$store.getters.getSuccessAlert
        this.$store.commit('resetSuccessAlert')
      }
    }
  }
}
</script>
<style scoped>
</style>
