<template>
  <v-container grid-list-xl text-xs-center style="height:100%">
    <v-layout class="d-flex flex-column justify-center align-center"  row wrap justify-center style="height:100%">
      <h1 class="text-center title-login" v-if="displayForm">{{ $t('generate_new_password') }}</h1>
      <v-flex class="col-sm-5 col-xs-12 d-flex flex-column justify-center align-center"  style="height:450px;">
        <div class=" elevation-3 login-box" style="background:#fff;padding:24px;width:100%;">
        <v-alert type="error" dismissible v-model="hasError">
          {{ $t(errorMessage) }}
        </v-alert>
        <v-alert type="success" dismissible v-model="hasSucceed">
          {{ $t('password_changed') }}
        </v-alert>
        <div class="text-center" v-if="isLoading && !displayForm">
          <v-progress-circular
            :size="50"
            color="#AB650D"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-form v-model="valid" @submit.prevent="newPassword" id="generate-new-password" v-if="displayForm">
          <v-text-field
            :label="$t('password')"
            type="password"
            v-model="password"
            :rules="passwordRules"
            color="#AB650D"
            required
            ></v-text-field>
            <v-text-field
            :label="$t('password_confirmation')"
            type="password"
            v-model="passwordConfirmation"
            :rules="passwordConfirmationRules"
            color="#AB650D"
            required
            class="mb-5"
            ></v-text-field>
            <v-btn type="submit" :loading="isLoading" :disabled="!valid" :large="true"  class="btn-wd" :block="true" color="#AB650D">{{ $t('submit') }}</v-btn>
        </v-form>
        <div class="text-center" v-if="badToken">
          <v-btn :to="{ name: 'lost-password' }" color="alert" class="btn-wd" tag="v-btn" :rounded="true" :outlined="true">{{ $t('new_password_generation_request') }}</v-btn>
        </div>
        <div class="text-center" v-if="displayLoginButton">
          <v-btn :to="{ name: 'login' }" color="#4a472f"   tag="v-btn" :rounded="true" :outlined="true">{{ $t('login') }}</v-btn>
        </div>
      </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      password: '',
      passwordConfirmation: '',
      passwordRules: [
        (v) => !!v || this.$t('password_required'),
        (v) => v.length > 7 || this.$t('password_greater_than')
      ],
      passwordConfirmationRules: [
        (v) => !!v || this.$t('password_required'),
        (v) => v.length > 7 || this.$t('password_greater_than'),
        (v) => v === this.password || this.$t('password_should_be_identical')
      ],
      isLoading: true,
      hasError: false,
      errorMessage: null,
      displayForm: false,
      valid: false,
      hasSucceed: false,
      displayLoginButton: false,
      badToken: false
    }
  },
  methods: {
    newPassword () {
      const data = {
        password: this.password,
        lostToken: this.$route.params.lostToken
      }
      this.isLoading = true
      this.$store.dispatch('setPassword', data)
        .then(() => {
          this.isLoading = false
          this.hasSucceed = true
          this.displayLoginButton = true
          this.displayForm = false
        })
        .catch(() => {
          this.hasError = true
          this.errorMessage = 'service_unavailable'
          this.isLoading = false
        })
    }
  },
  created () {
    const token = {
      lostToken: this.$route.params.lostToken
    }
    this.$store.dispatch('checkLostToken', token)
      .then(() => {
        this.displayForm = true
        this.isLoading = false
      })
      .catch(() => {
        this.hasError = true
        this.errorMessage = 'invalid_token'
        this.isLoading = false
        this.badToken = true
      })
  }
}
</script>

<style scoped lang="scss">
</style>
