export default (value) => {
  value = parseFloat(value)
  if (value !== 0 && !value) {
    return ''
  }
  if (value % 1 !== 0) {
    return formatNumber(addZeroes(value.toFixed(2)))
  }
  return formatNumber(addZeroes(value.toFixed(0)))
}

function formatNumber (num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

function addZeroes (num) {
  var value = Number(num)
  num = String(value)
  var res = num.split('.')
  if (res.length === 1 || res[1].length < 3) {
    value = value.toFixed(2)
  }
  return value
}
