<template>
  <v-container
  class="px-7"
  fluid
  id="user-creation"
  pt-20
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="error"
      :timeout="6000"
      >
      {{ errorMessage }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{ name: 'admin-users-list' }" class="back-btn" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
        <h1 class="text-center mb-2">{{ $t('add_new_user') }}</h1>
        <hr class="mb-3">
      </v-col>
    </v-row>
    <UserForm :form="form" :isLoading="isLoading" :isUpdating="false" @validate="validate" />
  </v-container>
</template>

<script>
import UserForm from './UserForm'
export default {
  components: {
    UserForm
  },
  data () {
    return {
      form: {
        email: '',
        name: '',
        roles: 'ROLE_USER',
        status: 1,
        companies: [],
        valid: false
      },
      isLoading: false,
      snackbar: false
    }
  },
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    errorMessage () {
      return this.$t('an_error_occured')
    },
    getCurrentCompanyHash () {
      if (this.isSuperAdmin) {
        return ''
      }
      return this.$store.getters.getCurrentCompanyHash
    }
  },
  methods: {
    validate () {
      if (this.form.valid) {
        const payload = {
          ...this.form,
          companyHash: this.getCurrentCompanyHash
        }
        payload.roles = [payload.roles]
        this.isLoading = true
        this.$store.dispatch('createUser', payload)
          .then(() => {
            const message = this.$t('user_sucessfully_created')
            this.$store.commit('setSuccessAlert', message)
            this.isLoading = false
            this.$router.push({ name: 'admin-users-list' })
          })
          .catch(() => {
            this.isLoading = false
            this.snackbar = true
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
