import { store } from '../store/index.js'

async function notAuthGuard (to, from, next) {
  const token = store.getters.getToken
  if (token) {
    next('/')
  } else {
    const auth = await store.dispatch('initAuth')
    if (auth) {
      next('/')
    } else {
      next()
    }
  }
}

export default notAuthGuard
