<template>
  <v-container
  class="px-7"
  fluid
  id="bond-detail"
  pt-20
  >
  <v-row>
    <v-col cols="12">
      <v-btn :to="{ name: 'bond-management' }" class="back-btn" color="#AB650D" dark>
        <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
      </v-btn>
      <h1 class="text-center mb-2">
        <div justify="center" align="center" >
        <v-progress-circular
          :size="70"
          :width="4"
          color="#4a4a30"
          indeterminate
          v-if="isLoading"
          justify="center"
          align="center"
        ></v-progress-circular>
      </div>
        <span v-if="bond && !isLoading">
          <template v-if="bond.nr_acte">{{ $t('bond_nr')}} {{ bond.nr_acte }}</template>
          <template v-else>{{ $t('bond_request') }}</template>
        </span>
        <span v-if="!isLoading && !bond">{{ $t('bond_not_found') }}</span>
      </h1>
      <hr class="mb-3">

    </v-col>
  </v-row>
    <v-row
    >
      <v-col cols="12" md="6">

        <div class="details-fiche elevation-4" justify="center" align="center" >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
          v-if="isLoading"
        ></v-skeleton-loader>
        <h3 v-if="bond && !isLoading">{{ $t('bond_data') }}</h3>
        <v-simple-table class="mt-6" v-if="bond && !isLoading">
          <template v-slot:default>
            <tbody>
              <tr v-if="bond.type_1 === 'CO' && bond.rappel_nr">
                <td colspan="2">
                  <b>{{ $t('parent_bond')}}</b>
                </td>
                <td colspan="2">
                  <router-link :to="{ name: 'bond-detail', params: { hash: bond.rappel_nr.hash } }">
                    {{ bond.rappel_nr.lib_int }}
                  </router-link>
                </td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('company')}}</b></td>
                <td colspan="2" v-if="bond.raw_company">{{ bond.raw_company }}</td>
                <td colspan="2" v-else>{{ bond.company.name }} | {{ bond.company.nr_amo }} | {{ bond.company.vat_number }}</td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('bond_type')}}</b></td>
                <td colspan="2">{{ displayType }}</td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('reference_externe')}}</b></td>
                <td colspan="2">{{ bond.reference_externe }}</td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('date_acte')}}</b></td>
                <td colspan="2">{{ bond.date_acte | formatDate }}</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('nr_caut')}}</b></td>
                <td colspan="2">{{ bond.nr_caut }}</td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('nr_acte')}}</b></td>
                <td colspan="2">{{ bond.nr_acte }}</td>
              </tr>
              <tr>
                <td><b>{{ $t('montant_devise')}}</b></td>
                <td class="text-right">{{ bond.montant_devise | formatPrice }} {{ bond.devise }}</td>
                <td><b>{{ $t('montant_initial')}}</b></td>
                <td class="text-right">{{ bond.montant_initial | formatPrice }}</td>
              </tr>
              <tr>
                <td><b>{{ $t('montant_engage')}}</b></td>
                <td class="text-right">{{ bond.montant_engage | formatPrice }}</td>
                <td><b>{{ $t('montant_travaux')}}</b></td>
                <td class="text-right">
                  <span v-if="bond.montant_travaux">
                    {{ bond.montant_travaux | formatPrice }}
                  </span>
                </td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('montant_lettre')}}</b></td>
                <td colspan="2">{{ bond.montant_lettre }}</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('montant_droit_garde')}}</b></td>
                <td colspan="2" class="text-right">{{ bond.montant_droit_garde | formatPrice }} {{ bond.devise }}</td>
              </tr>
              <tr>

              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('langue')}}</b></td>
                <td colspan="2">{{ bond.langue}}</td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('lib_int')}}</b></td>
                <td colspan="2">{{ bond.lib_int }}</td>
              </tr>
              <tr>
                <td colspan="2"><b>{{ $t('date_saisie_dec')}}</b></td>
                <td colspan="2">{{ bond.date_saisie_dec | formatDate }}</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('date_liberation_totale')}}</b></td>
                <td colspan="2">{{ bond.date_liberation_totale | formatDate }}</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('date_accorde')}}</b></td>
                <td colspan="2">{{ bond.date_accorde | formatDate }}</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('p_redevance')}}</b></td>
                <td colspan="2">{{ bond.p_redevance }} %</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('status_facturation')}}</b></td>
                <td colspan="2">{{ bond.status }}</td>
              </tr>
              <tr v-if="isSuperAdmin">
                <td colspan="2"><b>{{ $t('export_flag')}}</b></td>
                <td colspan="2">{{ bond.export_flag }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        </div>
      </v-col>
        <v-col cols="12" md="6">
          <div class="details-fiche elevation-4" justify="center" align="center">
            <v-skeleton-loader
              class="mx-auto"
              max-width="100%"
              type="card"
              v-if="isLoading"
            ></v-skeleton-loader>
            <h3 v-if="bond && !isLoading">{{ $t('liberations') }}</h3>
            <v-simple-table class="mt-6" v-if="bond && !isLoading">
              <template v-slot:default>
                <tbody>
                  <tr v-for="liberation in liberations" :key="liberation.hash">
                    <td v-if="liberation.is_fully_released"><v-icon>mdi-lock</v-icon></td>
                    <td v-else><v-icon>mdi-lock-open-outline</v-icon></td>
                    <td><b>{{ liberation.date_lib | formatDate }}</b></td>
                    <td class="text-right">{{ liberation.amount | formatPrice }} {{ bond.devise }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        <div class="details-fiche elevation-4 mt-6" justify="center" align="center" v-if="bond && !isLoading">
        <h3 v-if="bond && !isLoading"><span>{{ $t('work_nature') }}</span></h3>
        <div class="mt-6 desc-caut" v-if="bond && !isLoading">
          <p class="text-left">{{ text }}</p>
        </div>
         </div>
         <div
          class="details-fiche elevation-4 mt-6"
          justify="center"
          align="center"
          v-if="bond && !isLoading"
        >
            <h3 v-if="bond && !isLoading" class="mb-2">{{ $t('beneficiaires') }}</h3>
            <div v-for="bl in beneficiaires" :key="bl.id" class="benef-box">
              <p class="text-left font-weight-bold">{{ bl.beneficiaire.name }}</p>
              <p class="text-left">{{ bl.beneficiaire.street }} {{ bl.beneficiaire.number }} </p>
              <p class="text-left" v-if="bl.beneficiaire.post_code || bl.beneficiaire.city || bl.beneficiaire.country">
                {{ bl.beneficiaire.post_code }} {{ bl.beneficiaire.city }} {{ $t(bl.beneficiaire.country) }}
              </p>
            </div>
         </div>
         <div class="details-fiche elevation-4 mt-6" justify="center" align="center" v-if="bond && !isLoading">

            <h3>{{ $t('edoc') }}</h3>
            <p v-for="file in edocs" :key="file.id" @click="download(file)" class="pointer text-left"><v-icon>mdi-paperclip</v-icon>{{ $t('doc' + file.type) }}: {{ file.name }}</p>
         </div>
       </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      bondDetails: null,
      isLoading: true
    }
  },
  created () {
    this.fetchBond()
  },
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    bond () {
      if (this.bondDetails) {
        return this.bondDetails.bond
      }
      return null
    },
    text () {
      if (this.bondDetails && this.bondDetails.text) {
        return this.bondDetails.text.nat_travaux
      }
      return null
    },
    edocs () {
      if (this.bondDetails) {
        return this.bondDetails.edocs
      }
      return null
    },
    liberations () {
      if (this.bondDetails) {
        return this.bondDetails.bond.liberations
      }
      return null
    },
    beneficiaires () {
      if (this.isLoading) {
        return []
      }
      return this.bondDetails.bond.beneficiaires
    },
    displayType () {
      if (this.bond.type_2 === 'LB') {
        return this.$t('loi_breyne')
      }
      if (this.bond.type_2 === 'PU') {
        return this.$t('common_public')
      }
      if (this.bond.type_2 !== 'PR') {
        return ''
      }
      if (this.bond.texte_special !== '1') {
        return this.$t('common_private')
      }
      return this.$t('special_private')
    }
  },
  methods: {
    download (file) {
      this.$store.dispatch('downloadFile', file)
    },
    fetchBond () {
      this.isLoading = true
      this.$store.dispatch('fetchBond', this.$route.params.hash)
        .then(response => {
          this.isLoading = false
          this.bondDetails = response
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  },
  watch: {
    $route () {
      this.fetchBond()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table td {
  height: 36px;
}
.benef-box {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.benef-box p {
  padding: 5px 0px;
  margin: 5px 0px;
}
</style>
