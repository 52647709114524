<template>
  <v-container
  class="px-7"
  fluid
  id="home"
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="10000"
      >
      {{ successAlert }}
    </v-snackbar>
    <v-row v-if="!isSuperAdmin">
      <v-col cols="12">
        <v-btn :to="{ name: 'home' }" class="back-btn mb-5" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="justify-start tableau_company elevation-4 pa-4 pt-0"
    >
      <v-col class="text-left pt-0">
        <div>
          <div id="tools_company" class="d-flex justify-space-between align-center">
            <CompanyInfo :company="company" />
            <Export
              :companyHash="companyHashExport"
              :selectedStateStatus="isSuperAdmin ? params.filter_status : selectedStateStatus"
            />
          </div>
          <ExportFrom v-if="isSuperAdmin" />
            <Datatable
              :bonds="bonds"
              v-if="!isSuperAdmin"
              :isMomentary="false"
              :selectedStateStatus="selectedStateStatus"
              @setSelectedStateStatus="setSelectedStateStatus"
            />
            <AdminDatatable :bonds="bonds" @refresh="liberationSuccess" :params="params" v-else/>
            <template v-if="!isSuperAdmin">
              <div class="momentary-company" v-for="(mCompany, i) in getMomentaryCompanies" :key="mCompany.hash">
                <hr>
                <div id="tools_company" class="d-flex justify-space-between align-center pt-10">
                  <CompanyInfo :company="mCompany" />
                  <Export
                    :companyHash="mCompany.hash"
                    :selectedStateStatus="momentarySelectedStateStatus[i]"
                  />
                </div>
                <Datatable
                  :bonds="getMomentaryBonds[i]"
                  :isMomentary="true"
                  :selectedStateStatus="momentarySelectedStateStatus[i]"
                  @setSelectedStateStatus="setSelectedMomentaryStateStatus($event, i)"
                />
              </div>
            </template>
        </div>
        <div v-if="!isLoading && !company">
          <h3 class="text-center" v-if="!getCurrentCompanyHash">{{ $t('no_company_assigned') }}</h3>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from './Bonds/Datatable'
import AdminDatatable from './Bonds/AdminDatatable'
import Export from './Export'
import ExportFrom from './ExportFrom'
import CompanyInfo from './CompanyInfo'
export default {
  name: 'home',
  components: {
    Datatable,
    AdminDatatable,
    Export,
    ExportFrom,
    CompanyInfo
  },
  data () {
    return {
      hasError: false,
      isLoading: true,
      errorMessage: null,
      successAlert: null,
      snackbar: false,
      shouldReload: true,
      selectedStateStatus: 1,
      momentarySelectedStateStatus: [],
      params: {
        page: 0,
        limit: 10,
        search: '',
        filter_status: 1,
        companyHash: null
      }
    }
  },
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    company () {
      return this.$store.getters.getCurrentCompany
    },
    bonds () {
      return this.$store.getters.getBonds
    },
    getCurrentCompanyHash () {
      return this.$store.getters.getCurrentCompanyHash
    },
    getMomentaryCompanies () {
      return this.$store.getters.getMomentaryCompanies
    },
    getMomentaryBonds () {
      return this.$store.getters.getMomentaryBonds
    },
    companyHashExport () {
      if (this.isSuperAdmin) {
        if (this.getCurrentCompanyHash) {
          return this.getCurrentCompanyHash
        }
        return 'all'
      }
      if (this.company) {
        return this.company.hash
      }
      return ''
    }
  },
  created () {
  },
  activated () {
    this.loadBonds()

    if (this.$store.getters.getSuccessAlert) {
      this.snackbar = true
      this.successAlert = this.$store.getters.getSuccessAlert
      this.$store.commit('resetSuccessAlert')
      this.loadBonds()
    }
  },
  methods: {
    loadBonds () {
      if (this.isSuperAdmin) {
        this.$store.dispatch('fetchAdminBonds', this.params)
      } else {
        if (this.$store.getters.getCurrentCompanyHash) {
          this.$store.dispatch('fetchCurrentCompany')
            .then(() => {
              for (let i = 0; i < this.getMomentaryCompanies.length; ++i) {
                this.momentarySelectedStateStatus.push(1)
              }
            })
        }
      }
    },
    liberationSuccess () {
      this.snackbar = true
      this.successAlert = this.$t('liberation_successfully_added')
      this.loadBonds()
    },
    setSelectedStateStatus (status) {
      this.selectedStateStatus = status
    },
    setSelectedMomentaryStateStatus (status, id) {
      this.$set(this.momentarySelectedStateStatus, id, status)
    }
  }
}
</script>
<style scoped>
</style>
