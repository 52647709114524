<template>
  <div id="bond-form-container">
    <v-row class="justify-center">
      <v-col cols="12" :sm="isProcessing ? 12 : 12" md="12">
        <div class="details-fiche elevation-4 mt-n3" justify="center" align="center">
          <v-row>
            <v-col cols="12" class="justify-center" v-if="form.isComplementary">
              <v-alert type="info">
                {{ $t('this_is_complementary') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-form
            v-model="form.valid"
            id="bond-form"
            @submit.prevent="validate"
            >
            <v-autocomplete
              id="form-company"
              v-if="isSuperAdmin && !isComplementary && !isProcessing"
              v-model="form.selectedCompanyHash"
              color="#AB650D"
              item-color="brown"
              dense
              :label="$t('companies') + ' *'"
              :items="companies"
              item-value="hash"
              item-text="label"
              :rules="[v => !!v || $t('company_needed')]"
              @change="updateCompanyHash"
            ></v-autocomplete>
            <v-switch
              v-if="isSuperAdmin && !isProcessing"
              v-model="isMomentary"
              :label="$t('is_momentary')"
            ></v-switch>
            <v-text-field
              color="#AB650D"
              v-if="isSuperAdmin && !isProcessing && isMomentary"
              v-model="form.vat_number"
              :label="$t('vat_number')"
              @keyup="formatVatNumber(form.vat_number)"
              required
              autocomplete="off"
            ></v-text-field>
            <v-file-input
              v-if="isSuperAdmin && !isProcessing && isMomentary"
              :label="$t('soc_mom_document') + ' *'"
              color="#AB650D"
              v-model="form.soc_mom_document"
              accept="application/pdf"
              @change="$emit('updateFile', form.soc_mom_document, '003')"
              required
              dense
            ></v-file-input>
            <v-text-field
              v-if="isProcessing"
              v-model="form.rawCompany"
              :label="$t('companies') + ' *'"
              :rules="[v => !!v || $t('company_needed')]"
            ></v-text-field>
            <v-checkbox
              id="form-for-momentary"
              v-if="!isSuperAdmin || isProcessing"
              color="#AB650D"
              v-model="form.forMomentary"
              :label="$t('for_momentary')"
              required
              dense
            ></v-checkbox>
            <v-file-input
              v-if="!bondDetail && form.forMomentary"
              :label="$t('company_form')"
              color="#AB650D"
              v-model="form.momentary_company_document"
              accept="application/pdf"
              @change="$emit('updateFile', form.momentary_company_document, '003')"
              dense
            ></v-file-input>
            <div class="pb-2" v-if="form.forMomentary && bondDetail">
              <h5 v-if="form.momentary_company_filename">{{ $t('current_company_form') }}: </h5>
              <h5>{{ form.momentary_company_filename }}</h5>
              <v-file-input
                v-if="shouldUpdateMomentaryCompanyFile"
                :label="$t('company_form')"
                v-model="form.momentary_company_document"
                accept="application/pdf"
                @change="$emit('updateFile', form.momentary_company_document, '003')"
                dense
              ></v-file-input>
            <v-btn v-if="!shouldUpdateMomentaryCompanyFile" @click="shouldUpdateMomentaryCompanyFile = true">{{ $t('update_that_document')}}</v-btn>
            <v-btn v-if="shouldUpdateMomentaryCompanyFile" @click="shouldUpdateMomentaryCompanyFile = false">{{ $t('cancel')}}</v-btn>
            </div>
            <v-file-input
              id="form-contract"
              v-if="!bondDetail"
              :label="$t('please_link_contract') + ' *'"
              color="#AB650D"
              v-model="form.linked_document"
              :rules="[v => !!v || $t('linked_document_required')]"
              accept="application/pdf"
              @change="$emit('updateFile', form.linked_document, '001')"
              dense
            ></v-file-input>
            <div class="mt-6 mb-8" v-else>
              <div class="mb-2 file-input-container">
                <h5 class="file-input-items mr-2">{{ $t('linked_document') }}: {{ form.linked_filename }} </h5>
                <v-file-input
                  v-if="shouldUpdateFile"
                  :label="$t('please_link_contract') + ' *'"
                  v-model="form.linked_document"
                  :rules="[v => !!v || $t('linked_document_required')]"
                  accept="application/pdf"
                  @change="$emit('updateFile', form.linked_document, '001')"
                  dense
                  class="file-input-items"
                ></v-file-input>
                <v-btn small class="file-input-items" v-if="!shouldUpdateFile" @click="shouldUpdateFile = true">{{ $t('update_that_document')}}</v-btn>
                <v-btn small class="file-input-items" v-if="shouldUpdateFile" @click="shouldUpdateFile = false">{{ $t('cancel')}}</v-btn>
              </div>
            </div>
            <v-row>
              <v-col cols="12" md="6">
                <v-select
                  id="form-type"
                  color="#AB650D"
                  item-color="brown"
                  v-model="form.type_2"
                  :items="types"
                  :rules="[v => !!v || $t('type_needed')]"
                  :label="$t('type') + ' *'"
                  item-text="name"
                  item-value="value"
                  required
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  id="form-langue"
                  color="#AB650D"
                  item-color="brown"
                  v-model="form.langue"
                  :items="langues"
                  :rules="[v => !!v || $t('langue_needed')]"
                  :label="$t('langue_acte') + ' *'"
                  required
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-file-input
              v-if="!bondDetail && form.type_2 === 4"
              :label="$t('texte_special_doc') + ' *'"
              color="#AB650D"
              v-model="form.texte_special_document"
              accept="application/pdf"
              @change="$emit('updateFile', form.texte_special_document, '002')"
              required
              dense
            ></v-file-input>
            <div class="pb-2 m-4" v-if="form.type_2 === 4 && bondDetail">
              <div class="mb-6 mt-4 file-input-container">
                <h5 class="file-input-items mr-2" v-if="form.texte_special_filename">{{ $t('current_texte_special_form') }}: {{ form.texte_special_filename }}</h5>
                <v-file-input
                  v-if="shouldUpdateSpecialTexte"
                  :label="$t('texte_special_doc') + ' *'"
                  v-model="form.texte_special_document"
                  :rules="[v => !!v || $t('texte_special_doc_required')]"
                  accept="application/pdf"
                  @change="$emit('updateFile', form.texte_special_document, '002')"
                  dense
                  class="file-input-items"
                ></v-file-input>
                <v-btn small class="file-input-items" v-if="!shouldUpdateSpecialTexte" @click="shouldUpdateSpecialTexte = true">{{ $t('update_that_document')}}</v-btn>
                <v-btn small class="file-input-items" v-if="shouldUpdateSpecialTexte" @click="shouldUpdateSpecialTexte = false">{{ $t('cancel')}}</v-btn>
              </div>
            </div>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    id="form-montant-devise"
                    color="#AB650D"
                    v-model="form.montant_devise"
                    :rules="montantDeviseRules"
                    :label="$t('montant_devise') + ' *'"
                    type="number"
                    required
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-if="montantInitial"
                    v-model="montantInitial"
                    :label="$t('montant_initial')"
                    type="number"
                    readonly
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <!-- <v-text-field
                    color="#AB650D"
                    v-model="form.montant_travaux"
                    :rules="montantTravauxRules"
                    :label="$t('montant_travaux')"
                    type="number"
                    required
                    dense
                  ></v-text-field> -->
                </v-col>
              </v-row>
            <v-text-field
              id="form-lib-int"
              color="#AB650D"
              v-model="form.lib_int"
              :label="$t('lib_int') + ' *'"
              :rules="libIntRules"
              required
              :counter="90"
              autocomplete="off"
              dense
            ></v-text-field>
            <v-text-field
              id="form-reference-externe"
              color="#AB650D"
              v-model="form.reference_externe"
              :label="$t('your_ref_ext')"
              :rules="referenceExterneRules"
              required
              :counter="30"
              autocomplete="off"
              dense
            ></v-text-field>
            <template v-if="form.selectedCompanyHash || companyHash">
              <div
                v-for="(selectedBeneficiaire, indice) in form.selectedBeneficiaires"
                :key="selectedBeneficiaire"
              >
                <v-row>
                  <v-col cols="12" :md="getColumnSize(selectedBeneficiaire, indice)">
                    <v-autocomplete
                      id="form-beneficiaire"
                      v-model="form.selectedBeneficiaires[indice]"
                      :items="filteredBeneficiaires"
                      :loading="areBeneficiairesLoading"
                      :label="$t('beneficiaire') + ' ' + (indice + 1) + ' *'"
                      item-value="id"
                      item-text="label"
                      :rules="[v => !!v || $t('beneficiaire_required')]"
                      required
                      dense
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    v-if="selectedBeneficiaire"
                  >
                    <BeneficiaireTooltip
                      :beneficiaireId="selectedBeneficiaire"
                      :beneficiaires="filteredBeneficiaires"
                    />
                  </v-col>
                  <v-col
                    md="1"
                    v-if="selectedBeneficiaire"
                    @click="openBenefModal(selectedBeneficiaire)"
                  >
                    <v-icon
                      v-if="isBeneficiaireLocked(selectedBeneficiaire)"
                      class="mx-2 pointer"
                    >
                      mdi-pencil-plus
                    </v-icon>
                    <v-icon v-else class="mx-2 pointer">mdi-pencil</v-icon>
                  </v-col>
                  <v-col
                    v-if="indice > 0"
                    @click="form.selectedBeneficiaires.splice(indice,1)"
                    md="1"
                  >
                    <v-btn color="#bd6161" x-small fab>
                      <v-icon class="mx-2 pointer" color="#fff">mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </template>
            <v-btn
              v-if="companyHash"
              @click="form.selectedBeneficiaires.push(null)"
              small
              class="mr-2 mb-4"
            >
              {{ '+ ' + $t('beneficiaire') }}
            </v-btn>
            <BeneficiaireModal
              v-if="companyHash"
              @created="loadBeneficiaires"
              :companyHash="companyHash"
              :beneficiaires="beneficiaires"
              :previousBeneficiaires="form.selectedBeneficiaires"
              :isUpdating="isUpdating"
              :type_2="form.type_2"
              v-model="shouldBenefModalBeOpened"
              ref="benefModal"
            />
            <v-textarea
              v-if="isSuperAdmin"
              id="form-nature-travaux"
              color="#AB650D"
              v-model="form.nature_travaux"
              :label="$t('work_nature') + ' *'"
              :rules="natureTravauxRules"
              :counter="400"
              dense
            ></v-textarea>
              <v-btn v-if="isSuperAdmin" @click="autosetNatureTravaux" color="accent">
                <v-icon>mdi-content-copy</v-icon> {{ $t('prefill_nature_travaux') }}
              </v-btn>
            <div class="btn-row">
              <v-btn
                type="submit"
                :disabled="!form.valid"
                color="success"
                class="mr-4 mt-8"
                :loading="isLoading"
                x-large
                >
                {{ $t(isUpdating ? 'update' : 'validate') }}
              </v-btn>
            </div>
          </v-form>
          <v-btn
            v-if="isProcessing"
            color="primary"
            class="mr-4 mt-8"
            :loading="isMakeActeLoading"
            x-large
            @click="$emit('makeActe')"
          >
             {{ $t('transform_in_acte') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BeneficiaireModal from '../Beneficiaires/BeneficiaireModal'
import filterBeneficiaires from '../../filters/filterBeneficiaires'
import BeneficiaireTooltip from './Helpers/BeneficiaireTooltip'
export default {
  components: { BeneficiaireModal, BeneficiaireTooltip },
  props: ['bondDetail', 'isLoading', 'isUpdating', 'isProcessing', 'isMakeActeLoading', 'companies'],
  data () {
    return {
      langues: ['FR', 'NL'],
      shouldUpdateFile: false,
      shouldUpdateMomentaryCompanyFile: false,
      shouldUpdateSpecialTexte: false,
      beneficiaires: [],
      areBeneficiairesLoading: false,
      isMomentary: false,
      form: {
        isComplementary: false,
        complementaryBondHash: null,
        type_2: null,
        devise: 'EUR',
        montant_devise: null,
        langue: null,
        modalite_lib: null,
        custom_modalite_lib: null,
        montant_travaux: null,
        montant_travaux_tva: false,
        lib_int: null,
        nature_travaux: '',
        reference_externe: '',
        linked_document: null,
        beneficiaire: null,
        selectedBeneficiaires: [null],
        beneficiaireLBName: null,
        valid: false,
        selectedCompanyHash: null,
        forMomentary: false,
        momentary_company_document: null,
        texte_special_document: null,
        linked_filename: null,
        texte_special_filename: null,
        momentary_company_filename: null,
        companyHash: null,
        vat_number: null,
        soc_mom_document: null
      },
      momentaryCompanyHash: null,
      shouldBenefModalBeOpened: false,
      isValidate: false
    }
  },
  computed: {
    types () {
      return [
        { name: this.$t('common_public'), value: 1 },
        { name: this.$t('loi_breyne'), value: 2 },
        { name: this.$t('common_private'), value: 3 },
        { name: this.$t('special_private'), value: 4 }
      ]
    },
    modalites () {
      return [
        { text: this.$t('all_in_one'), value: '100' },
        { text: this.$t('partial_50_50'), value: '50/50' },
        { text: this.$t('other'), value: this.$t('other') }
      ]
    },
    isComplementary () {
      return this.$route.name === 'new-bond-complementary'
    },
    montantDeviseRules () {
      return [
        v => !!v || this.$t('montant_devise_required'),
        v => v > 0 || this.$t('montant_gt_0'),
        v => v < 999999999999.99 || this.$t('montant_too_high')
      ]
    },
    modaliteRules () {
      return [
        v => (!v || v.length < 91) || this.$t('modalite_lib_too_long')
      ]
    },
    montantTravauxRules () {
      return [
        v => (v > 0 || !v) || this.$t('montant_travaux_gt_0'),
        v => (v < 999999999999.99 || !v) || this.$t('montant_travaux_too_high')
      ]
    },
    natureTravauxRules () {
      return [
        v => !!v || this.$t('nature_travaux_required'),
        v => (v && v.length < 401) || this.$t('nature_travaux_too_long')
      ]
    },
    libIntRules () {
      return [
        v => !!v || this.$t('lib_int_required'),
        v => (v && v.length < 91) || this.$t('lib_int_too_long')
      ]
    },
    referenceExterneRules () {
      return [
        v => (!v || v.length < 31) || this.$t('reference_externe_too_long')
      ]
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    companyHash () {
      if (!this.isSuperAdmin) {
        return this.$store.getters.getCurrentCompanyHash
      }
      if (this.form.selectedCompanyHash) {
        return this.form.selectedCompanyHash
      }
      if (this.form.companyHash) {
        return this.form.companyHash
      }
      return null
    },
    montantInitial () {
      if (!this.form.montant_devise) {
        return null
      }
      const montant = parseFloat(this.form.montant_devise)
      let rest = montant % 10
      if (rest !== 0) {
        rest = 10 - rest
      }
      return montant + rest
    },
    nameRules () {
      return [
        v => !!v || this.$t('name_required'),
        v => (v && v.length < 401) || this.$t('name_too_long')
      ]
    },
    filteredBeneficiaires () {
      return filterBeneficiaires(this.beneficiaires)
    }
  },
  created () {
    if (this.bondDetail) {
      this.fillBondForm()
    }
    this.loadBeneficiaires()
    this.prefillSelectedCompany()
  },
  methods: {
    fillBondForm () {
      const bond = this.bondDetail.bond
      const edocs = this.bondDetail.edocs
      this.form.type_2 = this.formatType2(bond.type_2, bond.texte_special)
      this.form.isComplementary = bond.type_1 === 'CO'
      this.form.devise = bond.devise
      this.form.montant_devise = bond.montant_devise
      this.form.langue = bond.langue
      if (['50/50', '100'].includes(bond.modalite_lib)) {
        this.form.modalite_lib = bond.modalite_lib
      } else {
        this.form.modalite_lib = this.$t('other')
        this.form.custom_modalite_lib = bond.modalite_lib
      }
      this.form.montant_travaux = bond.montant_travaux
      this.form.montant_travaux_tva = bond.montant_travaux_tva === '1'
      this.form.lib_int = bond.lib_int
      this.form.nature_travaux = this.bondDetail.text.nat_travaux
      this.form.reference_externe = bond.reference_externe
      this.form.companyHash = this.bondDetail.bond.company.hash
      if (this.companies) {
        this.form.selectedCompanyHash = this.bondDetail.bond.company.hash
      }
      this.form.rawCompany = this.bondDetail.bond.raw_company
      if (edocs) {
        this.form.linked_filename = this.retrieveEdocByType('001') ? this.retrieveEdocByType('001').name : ''
        const momentaryCompanyEdoc = this.retrieveEdocByType('003')
        if (momentaryCompanyEdoc) {
          this.form.momentary_company_filename = momentaryCompanyEdoc.name
          this.form.forMomentary = true
        }
        const texteSpecialEdoc = this.retrieveEdocByType('002')
        if (texteSpecialEdoc) {
          this.form.texte_special_filename = texteSpecialEdoc.name
        }
      }
      this.form.selectedBeneficiaires = []
      for (let i = 0; i < bond.beneficiaires.length; ++i) {
        this.form.selectedBeneficiaires.push(bond.beneficiaires[i].beneficiaire.id)
      }
    },
    formatType2 (type2, special) {
      switch (type2) {
        case 'PU': return 1
        case 'LB': return 2
        case 'PR': return special === '1' ? 4 : 3
      }
    },
    formatVatNumber (value) {
      let newValue = value
      newValue = newValue.replace(/ |-|\./g, '')
      newValue = newValue.toUpperCase()
      this.form.vat_number = newValue
    },
    isVatNumberValid (number) {
      if (number.length !== 11 && number.length !== 12) {
        return false
      }
      number = number.substring(2)
      let last2Numbers = number.substring(number.length - 2)
      number = number.substring(0, number.length - 2)
      const rest = number % 97
      const verification = parseInt(97 - rest)
      last2Numbers = parseInt(last2Numbers)
      return last2Numbers === verification
    },
    retrieveEdocByType (type) {
      const edocs = this.bondDetail.edocs
      for (let i = 0; i < edocs.length; ++i) {
        if (edocs[i].type === type) {
          return edocs[i]
        }
      }
      return null
    },
    validate () {
      this.$emit('validate', this.form)
      this.isValidate = true
    },
    loadBeneficiaires (beneficiaire = null) {
      if (!this.companyHash) {
        this.beneficiaires = []
        return
      }
      this.areBeneficiairesLoading = true
      const lastIndice = this.form.selectedBeneficiaires.length - 1
      this.$store.dispatch('fetchBeneficiaires', this.companyHash)
        .then(response => {
          this.beneficiaires = response
          this.areBeneficiairesLoading = false
          if (beneficiaire) {
            this.form.selectedBeneficiaires[lastIndice] = beneficiaire
          }
        })
        .catch(() => {
          this.areBeneficiairesLoading = false
        })
    },
    updateCompanyFromModal (hash) {
      this.momentaryCompanyHash = hash
      this.$emit('reloadCompanies')
    },
    updateCompanyHash () {
      this.form.beneficiaire = null
      this.loadBeneficiaires()
    },
    autosetNatureTravaux () {
      const libInt = this.form.lib_int ? this.form.lib_int : ''
      const refExterne = this.form.reference_externe ? this.form.reference_externe : ''
      this.form.nature_travaux += libInt + ' ' + refExterne
    },
    prefillSelectedCompany () {
      if (!this.isSuperAdmin) {
        return
      }
      if (this.$store.getters.getCurrentCompanyHash && !this.form.selectedCompanyHash) {
        this.form.selectedCompanyHash = this.$store.getters.getCurrentCompanyHash
        this.updateCompanyHash()
      }
    },
    getColumnSize (selectedBeneficiaire, indice) {
      let baseSize = 9
      if (!selectedBeneficiaire) {
        baseSize += 3
      }
      if (indice === 1 && !selectedBeneficiaire) {
        baseSize -= 1
      }
      return baseSize
    },
    openBenefModal (beneficiaireId) {
      this.shouldBenefModalBeOpened = true
      const beneficiaire = this.findBeneficiaireWithId(beneficiaireId)
      this.$refs.benefModal.fillEditForm(beneficiaire, !beneficiaire.is_lock)
    },
    findBeneficiaireWithId (beneficiaireId) {
      for (let i = 0; i < this.beneficiaires.length; ++i) {
        if (this.beneficiaires[i].id === beneficiaireId) {
          return this.beneficiaires[i]
        }
      }
      return null
    },
    isBeneficiaireLocked (beneficiaireId) {
      const beneficiaire = this.findBeneficiaireWithId(beneficiaireId)
      if (!beneficiaire) {
        return true
      }
      return beneficiaire.is_lock
    },
    currentBeneficiaire () {
      if (this.form.type_2 === 2 && !this.isValidate) {
        return this.form.selectedBeneficiaires
      }
      return false
    }
  },
  watch: {
    companies () {
      if (this.momentaryCompanyHash) {
        this.form.selectedCompanyHash = this.momentaryCompanyHash
        this.momentaryCompanyHash = null
        this.updateCompanyHash()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #bond-form {
    margin-top: 40px;
  }
  .file-input-items {
    display: inline-block
  }
  .file-box {
    background-color: #f2f2f2;
    border-radius: 10px;
  }
</style>
