<template>
  <div id="tools">
    <v-tooltip top open-delay="200">
      <template v-slot:activator="{ on }">
        <v-btn text icon fab v-on="on">
          <v-icon x-large @click="pdf" class="tools" color="color">mdi-file-pdf-box</v-icon>
        </v-btn>
      </template>
      <span>{{ $t ('tooltip_action_pdf') }}</span>
    </v-tooltip>
     <v-tooltip top open-delay="200">
        <template v-slot:activator="{ on }">
          <v-btn text icon fab v-on="on">
            <v-icon x-large @click="csv" class="tools" color="color">mdi-file-excel</v-icon>
          </v-btn>
        </template>
        <span>{{ $t ('tooltip_action_excell') }}</span>
      </v-tooltip>
  </div>
</template>

<script>
export default {
  props: ['companyHash', 'selectedStateStatus'],
  computed: {
    company () {
      return this.$store.getters.getCurrentCompanyHash
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  },
  methods: {
    pdf () {
      if (this.isSuperAdmin && this.companyHash === 'undefined') {
        this.companyHash = 'all'
      }
      const options = {
        companyHash: this.companyHash,
        status: this.selectedStateStatus
      }
      this.$store.dispatch('getBondByCompanyPdf', options)
    },
    csv () {
      if (this.isSuperAdmin && this.companyHash === 'undefined') {
        this.companyHash = 'all'
      }
      const options = {
        companyHash: this.companyHash,
        status: this.selectedStateStatus
      }
      this.$store.dispatch('getBondByCompanyCsv', options)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
