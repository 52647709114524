<template>
  <v-container
  class="px-7"
  fluid
  id="company-creation"
  pt-20
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="error"
      :timeout="6000"
      >
      {{ errorMessage }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{ name: 'companies-list' }" class="back-btn" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
        <h1 class="text-center mb-5">{{ $t('add_new_company') }}</h1>
      </v-col>
    </v-row>
    <CompanyForm :form="form" :isLoading="isLoading" :isUpdating="false" @validate="validate"  />
  </v-container>
</template>

<script>
import CompanyForm from './CompanyForm'
export default {
  components: {
    CompanyForm
  },
  data () {
    return {
      form: {
        name: '',
        language: '',
        vat_number: '',
        bank_account: '',
        nr_amo: null,
        is_momentary: false,
        linked_companies: [],
        valid: false
      },
      isLoading: false,
      snackbar: false
    }
  },
  computed: {
    errorMessage () {
      return this.$t('an_error_occured')
    }
  },
  methods: {
    validate () {
      if (this.form.valid) {
        const payload = {
          ...this.form
        }
        this.isLoading = true
        this.$store.dispatch('createCompany', payload)
          .then(() => {
            const message = this.$t('company_sucessfully_created')
            this.$store.commit('setSuccessAlert', message)
            this.isLoading = false
            this.$router.push({ name: 'companies-list' })
          })
          .catch(() => {
            this.isLoading = false
            this.snackbar = true
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
