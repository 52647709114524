<template>
  <div id="companies-datatable" class="pa-5">
    <v-row>
      <v-col cols="10">
        <v-text-field
          v-model="search"
          class="align-self-center"
          color="#AB650D"
          :label="$t('search_for_a_company')"
          >
        </v-text-field>
      </v-col>
      <v-col cols="2">
        <div id="select_box" class="align-start pb-5">
          <v-btn
            :to="{ name: 'new-company' }"
            class="align-self-center"
            color="#AB650D"
            dark
            large
            >
            {{ $t('new') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      fixed-header
      class="table-compa"
      :loading="isLoading"
      :search="search"
      >
      <template v-slot:item.is_momentary="{ item }">
        <v-icon color="green" v-if="item.is_momentary">mdi-check-bold</v-icon>
        <v-icon color="red" v-else>mdi-close</v-icon>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-btn text icon color="#AB650D" dark v-on="on">
              <v-icon @click="editSingleItem(item)">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_action_edit_society') }}</span>
        </v-tooltip>

      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Datatable',
  props: ['companies', 'isLoading'],
  data () {
    return {
      snackbar: false,
      search: null
    }
  },
  filters: {
  },
  computed: {
    headers () {
      return [
        { text: this.$t('status'), align: 'left', value: 'export_flag' },
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('nr_amo'), value: 'nr_amo' },
        { text: this.$t('langue'), value: 'language' },
        { text: this.$t('vat_number'), value: 'vat_number' },
        { text: this.$t('is_momentary'), value: 'is_momentary' },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ]
    },
    filteredItems () {
      if (!this.companies) {
        return []
      }
      return this.companies
    }
  },
  methods: {
    displayIsMomentary (value) {
      if (value === 1) {
        return 'active'
      } else {
        return 'inactive'
      }
    },
    editSingleItem (item) {
      this.$router.push({ name: 'edit-company', params: { hash: item.hash } })
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table-header th{
    background:#e6e6e6 !important;
  }
</style>
