<template>
  <v-container
  class="px-7"
  fluid
  id="bond-update"
  pt-20
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="error"
      :timeout="6000"
      >
      {{ errorMessage }}
    </v-snackbar>
    <div justify="center" align="center" >
      <v-skeleton-loader
        class="mx-auto mt-3"
        max-width="100%"
        type="article"
        elevation="4"
        v-if="isBondFetchLoading"
      ></v-skeleton-loader>
    </div>
    <div v-if="!isBondFetchLoading && bondDetails">
      <v-row>
        <v-col cols="12">
          <v-btn :to="{ name: 'bond-management' }" class="back-btn" color="#AB650D" dark>
            <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
          </v-btn>
          <CompanyInfo class="text-center" :company="company" :noDefault="true" />
          <h1 class="text-center mb-5">{{ $t('update_new_bond') }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <BondForm
            :bondDetail="bondDetails"
            :isLoading="isUpdateLoading"
            :isUpdating="true"
            @validate="validate"
            @updateFile="setFileBase64"
          />
        </v-col>
        <v-col cols="12" sm="6" class="no-padding">
          <PdfViewer :files="files" :filesType="filesType"/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import PdfViewer from './PdfViewer'
import BondForm from './BondForm'
import CompanyInfo from '../CompanyInfo'
export default {
  components: {
    BondForm, CompanyInfo, PdfViewer
  },
  data () {
    return {
      bondDetails: null,
      isBondFetchLoading: true,
      isUpdateLoading: false,
      snackbar: false,
      fileBase64: {
        '001': null,
        '002': null,
        '003': null
      },
      serverFileBase64: {
        '001': null,
        '002': null,
        '003': null
      }
    }
  },
  computed: {
    errorMessage () {
      return this.$t('an_error_occured')
    },
    company () {
      const companies = this.$store.getters.getCompanies
      const companyHash = this.$store.getters.getCurrentCompanyHash
      if (companies) {
        for (let i = 0; i < companies.length; ++i) {
          if (companies[i].hash === companyHash) {
            return companies[i]
          }
        }
      }
      return null
    },
    files () {
      const filesTypes = ['001', '002', '003']
      const files = []
      for (let i = 0; i < filesTypes.length; ++i) {
        if (this.fileBase64[filesTypes[i]]) {
          files.push(this.fileBase64[filesTypes[i]])
        }
      }
      return files
    },
    filesType () {
      const filesTypes = ['001', '002', '003']
      const files = []
      for (let i = 0; i < filesTypes.length; ++i) {
        if (this.fileBase64[filesTypes[i]]) {
          files.push(filesTypes[i])
        }
      }
      return files
    }
  },
  created () {
    this.loadBondAndEdocs()
  },
  methods: {
    async loadBondAndEdocs () {
      try {
        const bondResponse = await this.$store.dispatch('fetchBond', this.$route.params.hash)
        this.isBondFetchLoading = false
        this.bondDetails = bondResponse
        let edocResponse, reader, type
        for (let i = 0; i < this.bondDetails.edocs.length; ++i) {
          edocResponse = await this.$store.dispatch('getFile', { hash: this.bondDetails.edocs[i].hash })
          type = this.bondDetails.edocs[i].type
          reader = new window.FileReader()
          reader.readAsDataURL(edocResponse)
          reader.onloadend = () => {
            this.fileBase64[type] = reader.result
            this.serverFileBase64[type] = reader.result
          }
        }
        this.shouldDisplayFile = true
      } catch (error) {
        this.isBondFetchLoading = false
      }
    },
    validate (form) {
      if (form.valid) {
        const payload = {
          ...form,
          bondHash: this.bondDetails.bond.hash
        }
        this.isUpdateLoading = true
        this.$store.dispatch('updateBond', payload)
          .then(() => {
            const message = this.$t('bond_sucessfully_updated')
            this.$store.commit('setSuccessAlert', message)
            this.isUpdateLoading = false
            this.$router.push({ name: 'bond-management' })
          })
          .catch(() => {
            this.isUpdateLoading = false
            this.snackbar = true
          })
      }
    },
    readFileAsync (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    async setFileBase64 (file, fileType) {
      if (!file) {
        this.fileBase64[fileType] = null
        if (this.serverFileBase64[fileType]) {
          this.fileBase64[fileType] = this.serverFileBase64[fileType]
        }
        return
      }
      this.fileBase64[fileType] = await this.readFileAsync(file)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
