<template>
  <div id="company-selection" v-if="companies.length > 1 && isHomePage || companies.length > 1 && isBondPage">
    <v-autocomplete
      v-model="companyHash"
      color="#AB650D"
      item-color="brown"
      dense
      :label="$t('companies')"
      :items="companies"
      item-value="hash"
      item-text="name"
      @change="updateCurrentCompany"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  created () {
    this.companyHash = localStorage.getItem('currentCompanyHash')
  },
  data () {
    return {
      companyHash: null
    }
  },
  computed: {
    companies () {
      return this.$store.getters.getCompanies
    },
    isHomePage () {
      return this.$route.name === 'home'
    },
    isBondPage () {
      return this.$route.name === 'bond-management'
    },
    getCurrentCompanyHash () {
      return this.$store.getters.getCurrentCompanyHash
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  },
  methods: {
    updateCurrentCompany () {
      this.$store.commit('setCurrentCompanyHash', this.companyHash)
      if (this.isBondPage) {
        this.$store.dispatch('fetchCurrentCompany')
      } else if (this.isHomePage) {
        this.initializeEdocs()
      }
    },
    initializeEdocs () {
      if (this.isSuperAdmin) {
        this.loadAllEdocs()
      } else {
        this.loadEdocsFromCompany()
      }
    },
    loadEdocsFromCompany () {
      this.$store.dispatch('fetchEdocsFromCompany', this.getCurrentCompanyHash)
    },
    loadAllEdocs () {
      this.$store.dispatch('fetchAdminEdocs', this.params)
    }
  }
}
</script>

<style lang="scss" scoped>
  #company-selection{
    margin-top: 25px;
    margin-right: 25px;
  }
</style>
