<template>
  <div id="bond-datatable" class="px-5">
    <div id="select_box" class="d-flex justify-space-between align-start">
      <v-select
        :value="selectedStateStatus"
        @change="setSelectedStateStatus"
        :items="stateStatus"
        style="max-width:350px"
        class="ml-3"
        color="#AB650D"
        item-color="brown"
        >
      </v-select>
      <v-text-field
          v-model="search"
          :label="$t('search_for_a_bond')"
          single-line
          hide-details
          style="max-width:350px"
          class="ml-3"
          color="#AB650D"
        ></v-text-field>
        <v-btn
          v-if="!isReadOnlyUser && !isMomentary"
          :to="{ name: 'new-bond' }"
          class="align-self-center"
          color="#AB650D"
          dark
          large
          >
          {{ $t('new') }}
        </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :search="search"
      fixed-header
      class="table-compa table-bonds-fideris"
      :loading="isCompanyLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="descending"
      :expanded.sync="expanded"
      show-expand
      >
      <template v-slot:item.export_flag="{ item }">
        <v-tooltip top open-delay="200" v-if="item.export_flag === 1">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text icon color="#000" dark>
              <v-icon>mdi-alert-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_open_request') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200" v-if="item.export_flag === 2 && !item.nr_acte">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text icon color="#000" dark>
              <v-icon v-on="on">mdi-account-lock</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_open_request') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.id="{ item }">
        R{{ item.id }}
      </template>
      <template v-slot:item.montant_devise="{ item }">
        <span class="nowrap">
          {{ item.montant_devise | formatPrice }}
        </span>
      </template>
      <template v-slot:item.date_acte="{ item }">
        <span class="nowrap">
          {{ item.date_acte | formatDate }}
        </span>
      </template>
      <template v-slot:item.montant_initial="{ item }">
        <span class="nowrap">
        {{ item.montant_initial | formatPrice }}
        </span>
      </template>
      <template v-slot:item.montant_engage="{ item }">
        <span class="nowrap">
        {{ item.montant_engage | formatPrice }}
        </span>
      </template>
      <template v-slot:item.reference_externe="{ item }">
        <template v-if="item.reference_externe">
          {{ item.reference_externe }}
        </template>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-btn text icon color="#AB650D" dark v-on="on">
              <v-icon @click="seeSingleItem(item)">mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_action_resume') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200" v-if="shouldAllowEdition(item)">
          <template v-slot:activator="{ on }">
            <v-btn text icon color="#AB650D" dark v-on="on">
              <v-icon @click="editSingleItem(item)">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_action_edit') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <BondDropdown :item="item" :headers="headers" />
      </template>
      <template v-slot:body.append v-if="[2, 4].includes(selectedStateStatus)">
        <tr class="bottom-value">
          <td colspan="5"><span class="total_dt">{{ $t('number_') }}: {{ lengthEngageBond }}</span></td>
          <td colspan="1" class="text-right"><span class="total_dt"><span class="total_dt_montant">{{ montantEngageBond | formatPrice }}</span></span></td>
          <td colspan="3"></td>
        </tr>
      </template>
      <v-alert slot="no-results" :value="true" type="error">
        {{ $t('no_results_for') }} "{{ search }}"
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import BondDropdown from './BondDropdown'
export default {
  components: {
    BondDropdown
  },
  name: 'Datatable',
  props: ['bonds', 'isMomentary', 'selectedStateStatus'],
  data () {
    return {
      search: null,
      sortBy: 'export_flag',
      descending: false,
      expanded: []
    }
  },
  filters: {
  },
  computed: {
    isReadOnlyUser () {
      return this.$store.getters.isReadOnlyUser
    },
    stateStatus () {
      return [
        { value: 1, text: this.$t('applications') },
        { value: 2, text: this.$t('balance_gt_0') },
        { value: 3, text: this.$t('balance_equal_0') },
        { value: 4, text: this.$t('every_type_of_balance') }
      ]
    },
    lengthEngageBond () {
      if (!this.bonds) {
        return []
      }
      const items = this.filteredItems
      const engages = []
      for (let i = 0; i < items.length; ++i) {
        if (items[i].montant_engage > 0) {
          engages.push(items[i])
        }
      }
      return engages.length
    },
    montantEngageBond () {
      if (!this.bonds) {
        return []
      }
      const items = this.filteredItems
      let montant = 0
      for (let i = 0; i < items.length; ++i) {
        if (items[i].montant_engage > 0) {
          montant += items[i].montant_engage
        }
      }
      return montant
    },
    isCompanyLoading () {
      return this.$store.getters.getIsCompanyLoading
    },
    headers () {
      if (this.selectedStateStatus === 1) {
        return [
          { text: this.$t('status'), align: 'center', value: 'export_flag' },
          { text: this.$t('reference'), align: 'left', value: 'id' },
          { text: this.$t('montant_devise'), align: 'right', value: 'montant_devise' },
          { text: this.$t('initial'), align: 'right', value: 'montant_initial' },
          { text: this.$t('solde'), align: 'right', value: 'montant_engage' },
          { text: this.$t('ref_ext'), value: 'reference_externe', sortable: false },
          { text: this.$t('works_wording'), value: 'lib_int', sortable: false },
          { text: this.$t('beneficiaire'), align: ' d-none', value: 'beneficiaire' },
          { text: this.$t('actions'), value: 'action', sortable: false }
        ]
      }
      return [
        { text: this.$t('nb_bond'), align: 'left', value: 'nr_acte' },
        { text: this.$t('date'), value: 'date_acte' },
        { text: this.$t('initial'), align: 'right', value: 'montant_initial' },
        { text: this.$t('solde'), align: 'right', value: 'montant_engage' },
        { text: this.$t('ref_ext'), value: 'reference_externe', sortable: false },
        { text: this.$t('works_wording'), value: 'lib_int', sortable: false },
        { text: this.$t('beneficiaire'), align: ' d-none', value: 'beneficiaire' },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ]
    },
    filteredItems () {
      if (!this.bonds) {
        return []
      }
      let items = this.bonds
      items = this.filterByStatus(items)
      items = this.addBeneficiaireProp(items)
      return items
    }
  },
  methods: {
    setSelectedStateStatus (status) {
      this.$emit('setSelectedStateStatus', status)
      this.filterByStatus(this.bonds)
    },
    filterByStatus (items) {
      const status = this.selectedStateStatus
      const filteredItems = []
      for (let i = 0; i < items.length; ++i) {
        if (status === 1) {
          if (items[i].nr_acte === null) {
            filteredItems.push(items[i])
          }
        } else if (status === 2) {
          if (items[i].nr_acte !== null && items[i].montant_engage > 0) {
            filteredItems.push(items[i])
          }
        } else if (status === 3) {
          if (items[i].nr_acte !== null && items[i].montant_engage < 1) {
            filteredItems.push(items[i])
          }
        } else if (status === 4) {
          if (items[i].nr_acte !== null) {
            filteredItems.push(items[i])
          }
        }
      }
      return filteredItems
    },
    addBeneficiaireProp (items) {
      let b, bLine
      for (let i = 0; i < items.length; ++i) {
        if (!items[i].beneficiaires[0]) {
          items[i].beneficiaire = ''
          continue
        }
        b = items[i].beneficiaires[0].beneficiaire
        bLine = b.name + ' ' + b.street + ' ' + b.number + ' ' + b.city + ' ' + b.post_code + ' ' + b.country
        items[i].beneficiaire = bLine
      }
      return items
    },
    seeSingleItem (item) {
      this.$router.push({ name: 'bond-detail', params: { hash: item.hash } })
    },
    editSingleItem (item) {
      this.$router.push({ name: 'edit-bond', params: { hash: item.hash } })
    },
    shouldAllowEdition (item) {
      if (this.isReadOnlyUser) {
        return false
      }
      if (item.nr_acte || item.export_flag > 1) {
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table td, .v-data-table th {
    padding: 0px 8px !important;
  }
  .v-data-table-header th{
    background:#e6e6e6 !important;
  }
  .total_dt{
    font-weight: bold;
    .total_dt_montant{
      margin-left: 5px;
    }
  }
  .total_dt_label{
    font-size: 12px;
    color: #777;
    font-weight: 900;
  }
  .bottom-th th{
    background: #f1f1f1 !important;
  }
  .drop-bg-td{
    background-color: #f5f5f5 !important;
  }
  .d-none{
    display:none!important
  }

</style>
