import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
    currentCompany: null,
    bonds: null,
    momentaryCompanies: [],
    momentaryBonds: [],
    isCompanyLoading: false
  },
  getters: {
    getCurrentCompany (state) {
      return state.currentCompany
    },
    getBonds (state) {
      return state.bonds
    },
    getIsCompanyLoading (state) {
      return state.isCompanyLoading
    },
    getMomentaryCompanies (state) {
      return state.momentaryCompanies
    },
    getMomentaryBonds (state) {
      return state.momentaryBonds
    }
  },
  mutations: {
    setCurrentCompany (state, payload) {
      state.currentCompany = payload
    },
    setBonds (state, payload) {
      state.bonds = payload
    },
    setIsCompanyLoading (state, payload) {
      state.isCompanyLoading = payload
    },
    setMomentaryCompanies (state, payload) {
      state.momentaryCompanies = payload
    },
    setMomentaryBonds (state, payload) {
      state.momentaryBonds = payload
    }
  },
  actions: {
    fetchCurrentCompany (context, payload) {
      context.commit('setIsCompanyLoading', true)
      const companyHash = context.getters.getCurrentCompanyHash
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/companies/' + companyHash, request)
          .then(response => {
            context.commit('setCurrentCompany', response.data.company)
            context.commit('setBonds', response.data.bonds)
            context.commit('setMomentaryCompanies', response.data.momentaryCompanies)
            context.commit('setMomentaryBonds', response.data.momentaryBonds)
            context.commit('setIsCompanyLoading', false)
            resolve()
          })
          .catch((error) => {
            context.commit('setIsCompanyLoading', false)
            reject(error)
          })
      })
    },
    fetchCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/companies/solo/' + payload, request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchCompanies (context) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/companies', request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchCompaniesForForms (context) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/companies/forms/', request)
          .then(response => {
            const companies = response.data
            let c
            for (let i = 0; i < companies.length; ++i) {
              c = companies[i]
              c.label = c.name + ' | ' + c.nr_amo + ' | ' + c.vatNumber
            }
            resolve(companies)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/companies', payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.put(path + '/api/companies/' + payload.companyHash, payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    softDeleteCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/companies/softDelete', { companyHash: payload }, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
