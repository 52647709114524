import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchBond (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios
          .get(path + '/api/bonds/' + payload, request)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchBondByCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios
          .get(path + '/api/bonds/ByCompany/' + payload, request)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    downloadFile (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        }
        axios
          .get(path + '/api/edocs/upload/' + payload.hash, request)
          .then((response) => {
            resolve(response.data)
            const filename = payload.name
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    downloadAllFile (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        }
        axios
          .get(path + '/api/bonds/allFiles/' + payload.hash, request)
          .then((response) => {
            resolve(response.data)
            const filename = 'archive_demande_' + payload.hash + '.zip'
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    downloadAllFileSince (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      let payloadSup = ''
      if (payload.bondIdLimit) {
        payloadSup = '/' + payload.bondIdLimit
      }
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        }
        axios
          .get(path + '/api/bonds/allFilesSinceBond/' + payload.bondId + payloadSup, request)
          .then((response) => {
            resolve(response.data)
            const filename = 'archive_demande_depuis_' + payload + '.zip'
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    getFile (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          responseType: 'blob'
        }
        axios
          .get(path + '/api/edocs/upload/' + payload.hash, request)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createBond (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      const formData = obtainFormData(payload)
      return new Promise((resolve, reject) => {
        axios
          .post(path + '/api/bonds', formData, {
            headers: {
              Authorization: token,
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    updateBond (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      const formData = obtainFormData(payload)
      return new Promise((resolve, reject) => {
        axios
          .post(path + '/api/bonds/update/' + payload.bondHash, formData, {
            headers: {
              Authorization: token,
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchAdminBonds (context, payload) {
      const params = {
        page: payload.page,
        limit: payload.limit,
        search: payload.search,
        filter_status: payload.filter_status,
        company_hash: payload.companyHash
      }
      context.commit('setIsCompanyLoading', true)
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          params
        }
        axios
          .get(path + '/api/bonds/master/', request)
          .then((response) => {
            context.commit('setBonds', response.data)
            context.commit('setIsCompanyLoading', false)
            resolve()
          })
          .catch((error) => {
            context.commit('setIsCompanyLoading', false)
            reject(error)
          })
      })
    },
    makeActe (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios
          .post(
            path + '/api/bonds/make-acte/' + payload,
            {},
            {
              headers: {
                Authorization: token
              }
            }
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    formalizeActe (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios
          .post(
            path + '/api/bonds/formalize-acte/' + payload.hash,
            { nr_acte: payload.nr_acte },
            { headers: { Authorization: token } }
          )
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    loadLiberableBond (context, payload) {
      const params = {
        is_public: payload.isPublic,
        nr_caut: payload.nrCaut
      }
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          },
          params
        }
        axios
          .get(path + '/api/bonds/liberable', request)
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}

function obtainFormData (payload) {
  const formData = new FormData()
  formData.append('texte_special', payload.type_2 === 4 ? '1' : '0')
  formData.append('type_2', formatType2(payload.type_2))
  formData.append('devise', payload.devise)
  formData.append('montant_devise', payload.montant_devise)
  formData.append('langue', payload.langue)
  if (['50/50', '100'].includes(payload.modalite_lib)) {
    formData.append('modalite_lib', payload.modalite_lib)
  } else {
    if (payload.custom_modalite_lib) {
      formData.append('modalite_lib', payload.custom_modalite_lib)
    }
  }
  if (parseFloat(payload.montant_travaux)) {
    formData.append('montant_travaux', payload.montant_travaux)
    formData.append('montant_travaux_tva', payload.montant_travaux_tva ? '1' : '0')
  }
  formData.append('lib_int', payload.lib_int)
  if (payload.vat_number) {
    formData.append('vat_number', payload.vat_number)
  }
  if (payload.soc_mom_document) {
    formData.append('soc_mom_document', payload.soc_mom_document)
  }
  formData.append('reference_externe', payload.reference_externe)
  if (payload.linked_document) {
    formData.append('linked_document', payload.linked_document)
  }
  if (payload.momentary_company_document && payload.forMomentary) {
    formData.append('momentary_company_document', payload.momentary_company_document)
  }
  if (payload.texte_special_document && payload.type_2 === 4) {
    formData.append('texte_special_document', payload.texte_special_document)
  }
  formData.append('companyHash', payload.companyHash)
  formData.append('nature_travaux', payload.nature_travaux)
  // The way of handling beneficiaire changes if it is a LOI BREYNE bond
  formData.append('beneficiaires', JSON.stringify(payload.selectedBeneficiaires))
  if (payload.complementaryBondHash) {
    formData.append('complementary_bond_hash', payload.complementaryBondHash)
  }
  formData.append('for_momentary', payload.forMomentary)
  if (payload.rawCompany) {
    formData.append('raw_company', payload.rawCompany)
  }
  return formData
}

function formatType2 (value) {
  switch (value) {
    case 1: return 'PU'
    case 2: return 'LB'
    case 3: return 'PR'
    case 4: return 'PR'
  }
}
