var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5",attrs:{"id":"bond-datatable"}},[_c('div',{staticClass:"d-flex justify-space-between align-start",attrs:{"id":"select_box"}},[_c('v-select',{staticClass:"ml-3",staticStyle:{"max-width":"350px"},attrs:{"value":_vm.selectedStateStatus,"items":_vm.stateStatus,"color":"#AB650D","item-color":"brown"},on:{"change":_vm.setSelectedStateStatus}}),_c('v-text-field',{staticClass:"ml-3",staticStyle:{"max-width":"350px"},attrs:{"label":_vm.$t('search_for_a_bond'),"single-line":"","hide-details":"","color":"#AB650D"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.isReadOnlyUser && !_vm.isMomentary)?_c('v-btn',{staticClass:"align-self-center",attrs:{"to":{ name: 'new-bond' },"color":"#AB650D","dark":"","large":""}},[_vm._v(" "+_vm._s(_vm.$t('new'))+" ")]):_vm._e()],1),_c('v-data-table',{staticClass:"table-compa table-bonds-fideris",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.search,"fixed-header":"","loading":_vm.isCompanyLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.descending,"expanded":_vm.expanded,"show-expand":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.descending=$event},"update:sort-desc":function($event){_vm.descending=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.export_flag",fn:function(ref){
var item = ref.item;
return [(item.export_flag === 1)?_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"#000","dark":""}},on),[_c('v-icon',[_vm._v("mdi-alert-circle-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t ('tooltip_open_request')))])]):_vm._e(),(item.export_flag === 2 && !item.nr_acte)?_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"#000","dark":""}},on),[_c('v-icon',_vm._g({},on),[_vm._v("mdi-account-lock")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t ('tooltip_open_request')))])]):_vm._e()]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" R"+_vm._s(item.id)+" ")]}},{key:"item.montant_devise",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.montant_devise))+" ")])]}},{key:"item.date_acte",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_acte))+" ")])]}},{key:"item.montant_initial",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.montant_initial))+" ")])]}},{key:"item.montant_engage",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"nowrap"},[_vm._v(" "+_vm._s(_vm._f("formatPrice")(item.montant_engage))+" ")])]}},{key:"item.reference_externe",fn:function(ref){
var item = ref.item;
return [(item.reference_externe)?[_vm._v(" "+_vm._s(item.reference_externe)+" ")]:_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.seeSingleItem(item)}}},[_vm._v("mdi-page-next-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t ('tooltip_action_resume')))])]),(_vm.shouldAllowEdition(item))?_c('v-tooltip',{attrs:{"top":"","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},on),[_c('v-icon',{on:{"click":function($event){return _vm.editSingleItem(item)}}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t ('tooltip_action_edit')))])]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('BondDropdown',{attrs:{"item":item,"headers":headers}})]}},([2, 4].includes(_vm.selectedStateStatus))?{key:"body.append",fn:function(){return [_c('tr',{staticClass:"bottom-value"},[_c('td',{attrs:{"colspan":"5"}},[_c('span',{staticClass:"total_dt"},[_vm._v(_vm._s(_vm.$t('number_'))+": "+_vm._s(_vm.lengthEngageBond))])]),_c('td',{staticClass:"text-right",attrs:{"colspan":"1"}},[_c('span',{staticClass:"total_dt"},[_c('span',{staticClass:"total_dt_montant"},[_vm._v(_vm._s(_vm._f("formatPrice")(_vm.montantEngageBond)))])])]),_c('td',{attrs:{"colspan":"3"}})])]},proxy:true}:null],null,true)},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"type":"error"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t('no_results_for'))+" \""+_vm._s(_vm.search)+"\" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }