import Vue from 'vue'
import Vuex from 'vuex'

import login from './login'
import company from './company'
import bond from './bond'
import alerts from './alerts'
import exports from './export'
import users from './users'
import liberations from './liberation'
import contacts from './contacts'
import addresses from './addresses'
import beneficiaires from './beneficiaires'
import edocs from './edocs'
import pdfPages from './bond/pdfPage'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    login,
    company,
    bond,
    alerts,
    exports,
    users,
    liberations,
    contacts,
    addresses,
    beneficiaires,
    edocs,
    pdfPages
  }
})
