<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" v-on="on" @click="resetForm">{{ $t('new') }}</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
          >
          {{ $t(form && form.id ? 'edit_address' : 'new_address') }}
        </v-card-title>
        <v-form
          v-model="form.valid"
          @submit.prevent="validate"
          id="address-form"
          ref="form"
        >
          <v-card-text>
            <v-text-field
              color="#AB650D"
              v-model="form.street"
              :rules="streetRules"
              :label="$t('street')"
              required
            ></v-text-field>
            <v-text-field
              color="#AB650D"
              v-model="form.number"
              :rules="numberRules"
              :label="$t('number')"
              required
            ></v-text-field>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.country"
              :rules="[v => !!v || $t('country_required')]"
              :items="countries"
              :label="$t('country')"
              @change="resetFormCity"
              required
            ></v-select>
            <v-text-field
              v-if="form.country !== 'BE'"
              color="#AB650D"
              v-model="form.city"
              :rules="cityRules"
              :label="$t('city')"
              required
            ></v-text-field>
            <v-autocomplete
              v-else
              color="#AB650D"
              :rules="belgiumCityRules"
              :label="$t('city')"
              :items="belgiumCities"
              return-object
              item-text="city"
              @change="setCityAndPostCode"
              :value="form.city"
              required
            ></v-autocomplete>
            <v-text-field
              color="#AB650D"
              v-model="form.post_code"
              :rules="postCodeRules"
              :label="$t('post_code')"
              required
            ></v-text-field>
            <v-select
              color="#AB650D"
              v-model="form.other"
              :rules="[v => !!v || $t('info_required')]"
              :items="displayedOthers"
              :label="$t('other')"
              required
            ></v-select>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!form.valid"
              color="success"
              class="mr-4 mt-8"
              :loading="isLoading"
              x-large
            >
              {{ $t(form && form.id ? 'update' : 'validate') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import belgiumCities from '../../assets/belgium_cities.json'
import Vue from 'vue'
export default {
  props: ['editingAddress', 'existingOthers'],
  data () {
    return {
      form: {
        valid: false,
        street: null,
        post_code: null,
        number: null,
        city: null,
        other: null,
        country: null,
        id: null
      },
      isLoading: false,
      menu: null,
      dialog: null,
      belgiumCities: []
    }
  },
  created () {
    this.setBelgiumCities()
    this.setOther()
  },
  computed: {
    displayedOthers () {
      const displayedOthers = []
      for (const other of this.others) {
        if (!this.existingOthers.includes(other.value) || other.value === this.form.other) {
          displayedOthers.push(other.value)
        }
      }
      return displayedOthers
    },
    countries () {
      return [
        { value: 'BE', text: this.$t('BE') },
        { value: 'FR', text: this.$t('FR') },
        { value: 'NL', text: this.$t('NL') },
        { value: 'DE', text: this.$t('DE') },
        { value: 'GB', text: this.$t('GB') },
        { value: 'LU', text: this.$t('LU') }
      ]
    },
    others () {
      return [
        { value: 'S', text: this.$t('siege_social') },
        { value: 'C', text: this.$t('correspondance') }
      ]
    },
    streetRules () {
      return [
        v => !!v || this.$t('street_required'),
        v => (v && v.length < 41) || this.$t('street_too_long'),
        v => (v && v.length > 4) || this.$t('street_too_short')
      ]
    },
    postCodeRules () {
      return [
        v => !!v || this.$t('post_code_required'),
        v => (v && v.length < 16) || this.$t('post_code_too_long'),
        v => (v && v.length > 1) || this.$t('post_code_too_short')
      ]
    },
    numberRules () {
      return [
        v => !!v || this.$t('number_required'),
        v => (v && v.length < 7) || this.$t('number_too_long')
      ]
    },
    cityRules () {
      return [
        v => !!v || this.$t('city_required'),
        v => (v && v.length < 31) || this.$t('city_too_long'),
        v => (v && v.length > 1) || this.$t('city_too_short')
      ]
    },
    belgiumCityRules () {
      return [
        v => !!v || this.$t('city_required')
      ]
    }
  },
  methods: {
    setOther () {
      if (this.form.other) {
        return
      }
      if (this.displayedOthers.includes('S')) {
        this.form.other = 'S'
        return
      }
      if (this.displayedOthers.includes('C')) {
        this.form.other = 'C'
      }
    },
    validate () {
      if (!this.form.id) {
        this.createNewAddress()
      } else {
        this.updateAddress()
      }
    },
    createNewAddress () {
      if (this.form.valid) {
        this.form.company = { hash: this.$route.params.hash }
        this.isLoading = true
        this.$store.dispatch('createAddress', this.form)
          .then(response => {
            this.isLoading = false
            this.dialog = false
            this.$emit('created')
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    updateAddress () {
      if (this.form.valid) {
        this.isLoading = true
        this.$store.dispatch('editAddress', this.form)
          .then(response => {
            this.isLoading = false
            this.dialog = false
            this.$emit('updated')
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    resetFormCity () {
      this.form.city = null
      this.form.post_code = null
    },
    setCityAndPostCode (city) {
      this.form.post_code = city.zip
      this.form.city = city.city
    },
    resetForm () {
      this.form = {
        valid: false,
        street: null,
        post_code: null,
        number: null,
        city: null,
        other: null,
        country: null,
        id: null
      }
      Vue.nextTick(() => {
        this.$refs.form.reset()
        this.setOther()
      })
    },
    setBelgiumCities () {
      this.belgiumCities = belgiumCities
    }
  },
  watch: {
    editingAddress (newValue) {
      if (!newValue) {
        return
      }
      this.form.street = newValue.street
      this.form.post_code = newValue.post_code
      this.form.number = newValue.number
      this.form.city = newValue.city
      this.form.other = newValue.other
      this.form.country = newValue.country
      this.form.id = newValue.id
      this.dialog = true
      this.$emit('resetEditingAddress')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
