import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    fetchBeneficiaires (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/beneficiaires/' + payload, request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createBeneficiaire (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/beneficiaires', payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editBeneficiaire (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.put(path + '/api/beneficiaires/' + payload.id, payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteBeneficiaire (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.delete(path + '/api/beneficiaires/' + payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
