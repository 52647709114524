<template>
  <div id="company-contact">
    <v-row class="justify-center">
      <v-col cols="12" sm="12">
        <div class="details-fiche no-p-top" justify="center" align="center">
          <v-snackbar
            v-model="snackbar"
            :bottom="true"
            color="success"
            :timeout="6000"
            >
            {{ message }}
          </v-snackbar>
          <CompanyHeader :company="company"/>
          <h3 class="text-right mb-5">
            <ContactModal
              @created="contactCreated"
              @updated="contactUpdated"
              @resetEditingContact="editingContact = null"
              :editingContact="editingContact"
              :existingContacts="existingContacts"
              />
          </h3>
          <v-simple-table height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t('type') }}</th>
                  <th class="text-left">{{ $t('value') }}</th>
                  <th class="text-left">{{ $t('comment') }}</th>
                  <th class="text-left">{{ $t('actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in contacts" :key="item.id">
                  <td>{{ item.type }}</td>
                  <td>{{ item.content }}</td>
                  <td>{{ item.comment }}</td>
                  <td>
                    <v-tooltip top open-delay="200">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="#AB650D" dark v-on="on">
                          <v-icon @click="deleteSingleItem(item)">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t ('delete_contact') }}</span>
                    </v-tooltip>
                    <v-tooltip top open-delay="200">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="#AB650D" dark v-on="on">
                          <v-icon @click="editSingleItem(item)">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t ('edit_contact') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ContactModal from './ContactModal'
import CompanyHeader from './Helpers/CompanyHeader'
export default {
  components: { ContactModal, CompanyHeader },
  props: ['contacts', 'company'],
  data () {
    return {
      message: null,
      snackbar: false,
      lastDeletedItem: null,
      lastDeletedIndice: null,
      editingContact: null
    }
  },
  computed: {
    existingContacts () {
      const existingContacts = []
      for (const contact of this.contacts) {
        if (!existingContacts.includes(contact.type)) {
          existingContacts.push(contact.type)
        }
      }
      return existingContacts
    }
  },
  methods: {
    contactCreated () {
      this.$emit('refresh')
      this.message = this.$t('contact_sucessfully_created')
      this.snackbar = true
    },
    contactUpdated () {
      this.$emit('refresh')
      this.message = this.$t('contact_sucessfully_updated')
      this.snackbar = true
    },
    deleteSingleItem (item) {
      const shouldDelete = confirm(this.$t('confirm_contact_delete'))
      if (!shouldDelete) {
        return
      }
      this.removeItem(item)
      this.$store.dispatch('deleteContact', item.id)
        .then(response => {
          this.message = this.$t('contact_sucessfully_deleted')
          this.snackbar = true
        })
        .catch(() => {
          this.revertLastItemDelete()
        })
    },
    removeItem (item) {
      for (let i = 0; i < this.contacts.length; ++i) {
        if (item.id === this.contacts[i].id) {
          this.lastDeletedIndice = i
          this.lastDeletedItem = item
          this.contacts.splice(i, 1)
        }
      }
    },
    revertLastItemDelete () {
      this.contacts.splice(this.lastDeletedIndice, 0, this.lastDeletedItem)
    },
    editSingleItem (item) {
      this.editingContact = item
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
