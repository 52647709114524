<template>
  <div id="bond-datatable" class="pa-5">
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="6000"
      >
      {{ $t('password_generation_sent') }}
    </v-snackbar>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      fixed-header
      class="table-compa"
      :loading="isLoading"
      >
      <template v-slot:item.roles="{ item }">
        {{ $t(item.roles) }}
      </template>
      <template v-slot:item.status="{ item }">
        {{ $t(displayStatus(item.status)) }}
      </template>
      <template v-slot:item.companies="{ item }">
        {{ item.companies | singleLineCompanies }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn text icon color="#AB650D" dark>
          <v-icon @click="editSingleItem(item)">mdi-pencil-outline</v-icon>
        </v-btn>
        <v-btn text icon color="#AB650D" dark>
          <v-icon @click="sendPasswordChangeRequest(item)">mdi-lock-reset</v-icon>
        </v-btn>
        <v-btn text icon color="#AB650D" dark>
          <v-icon @click="deleteSingleItem(item)">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Datatable',
  props: ['users', 'isLoading'],
  data () {
    return {
      snackbar: false
    }
  },
  filters: {
  },
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    headers () {
      if (this.isSuperAdmin) {
        return [
          { text: this.$t('username'), align: 'left', value: 'name' },
          { text: this.$t('email'), value: 'email' },
          { text: this.$t('role'), value: 'roles' },
          { text: this.$t('companies'), value: 'companies' },
          { text: this.$t('status'), value: 'status' },
          { text: this.$t('actions'), value: 'action', sortable: false }
        ]
      } else {
        return [
          { text: this.$t('username'), align: 'left', value: 'name' },
          { text: this.$t('email'), value: 'email' },
          { text: this.$t('role'), value: 'roles' },
          { text: this.$t('status'), value: 'status' },
          { text: this.$t('actions'), value: 'action', sortable: false }
        ]
      }
    },
    filteredItems () {
      if (!this.users) {
        return []
      }
      return this.users
    }
  },
  methods: {
    displayStatus (value) {
      if (value === 1) {
        return 'active'
      } else {
        return 'inactive'
      }
    },
    deleteSingleItem (item) {
      const shouldDelete = confirm(this.$t('confirm_user_delete'))
      if (!shouldDelete) {
        return
      }
      this.$store.dispatch('softDeleteUser', item.hash)
        .then(() => {
          const message = this.$t('user_sucessfully_deleted')
          this.$store.commit('setSuccessAlert', message)
          this.$emit('reload')
        })
    },
    editSingleItem (item) {
      this.$router.push({ name: 'edit-admin-user', params: { hash: item.hash } })
    },
    sendPasswordChangeRequest (item) {
      this.$store.dispatch('lostpassword', { email: item.email })
        .then(() => {
          this.snackbar = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table-header th{
    background:#e6e6e6 !important;
  }
</style>
