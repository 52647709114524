<template>
  <div id="bond-datatable" class="pa-5">
        <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="6000"
      >
      {{ successAlert }}
    </v-snackbar>
    <div id="select_box" class="d-flex justify-space-between align-start">
      <v-autocomplete
        v-model="params.companyHash"
        :loading="areCompaniesLoading"
        :items="companies"
        style="max-width:350px"
        class="ml-3"
        color="#AB650D"
        item-color="brown"
        @change="changeCompany"
        item-value="hash"
        item-text="name"
        clearable
        >
      </v-autocomplete>
      <v-text-field
          v-model="params.search"
          :label="$t('search_for_an_edoc')"
          single-line
          hide-details
          style="max-width:350px"
          class="ml-3"
          color="#AB650D"
          @input="paramsChanged"
        ></v-text-field>
        <EdocModal :companies="companies" @refresh="refresh" @snackbar="openSnackbar" />
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      fixed-header
      class="table-compa"
      @update:page="changePage"
      @update:options="changeOptions"
      :server-items-length="parseInt(total,10)"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      >
      <template v-slot:item.is_read_fideris="{ item }">
        <v-icon v-if="item.is_read_fideris" @click="downloadItem(item)">
          mdi-email-open-outline
        </v-icon>
        <v-icon v-else @click="downloadItem(item)">
          mdi-email-receive
        </v-icon>
      </template>
      <template v-slot:item.company="{ item }">
        {{ item.company.name }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ $t(item.type) }}
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn text icon color="#AB650D" dark>
          <v-icon @click="downloadItem(item)">mdi-download</v-icon>
        </v-btn>
      </template>
      <v-alert slot="no-results" :value="true" type="error">
        {{ $t('no_results_for') }} "{{ params.search }}"
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import EdocModal from './EdocModal'
export default {
  components: {
    EdocModal
  },
  name: 'Datatable',
  props: ['edocs', 'params'],
  data () {
    return {
      selectedCompany: this.$t('all_companies'),
      areCompaniesLoading: false,
      companies: [],
      snackbar: false
    }
  },
  filters: {
  },
  computed: {
    total () {
      if (this.edocs && this.edocs.total) {
        return this.edocs.total
      }
      return 0
    },
    isCompanyLoading () {
      return this.$store.getters.getIsCompanyLoading
    },
    headers () {
      return [
        { text: this.$t('status'), align: 'center', value: 'is_read_fideris' },
        { text: this.$t('companies'), value: 'company' },
        { text: this.$t('date'), value: 'created_at' },
        { text: this.$t('edoc_type'), value: 'type' },
        { text: this.$t('edoc'), value: 'name' },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ]
    },
    filteredItems () {
      if (!this.edocs) {
        return []
      }
      return this.edocs.edocs
    },
    successAlert () {
      return this.$t('edoc_successfully_added')
    }
  },
  created () {
    this.areCompaniesLoading = true
    this.$store.dispatch('fetchCompaniesForForms')
      .then(response => {
        this.companies = response
        this.companies.push({ name: this.$t('all_companies'), hash: null })
        this.areCompaniesLoading = false
      })
      .catch(() => {
        this.areCompaniesLoading = false
      })
  },
  methods: {
    openSnackbar () {
      this.snackbar = true
    },
    downloadItem (item) {
      this.$store.dispatch('downloadFile', item)
      this.$store.dispatch('setEdocAsRead', item.hash)
      this.setEdocAsReadFront(item)
    },
    setEdocAsReadFront (item) {
      item.is_read_fideris = true
    },
    updateCompanyHash (company) {
      this.$store.commit('setCurrentCompanyHash', company)
    },
    refresh () {
      this.$emit('reload')
    },
    loadDatas () {
      this.$store.dispatch('fetchAdminEdocs', this.params)
    },
    shouldAllowToMakeLiberation (item) {
      return (item.export_flag > 1 && item.nr_acte)
    },
    shouldAllowProcessing (item) {
      if (item.nr_acte) {
        return false
      }
      return true
    },
    changeCompany () {
      this.paramsChanged()
      this.updateCompanyHash(this.params.companyHash)
    },
    paramsChanged () {
      this.params.page = 0
      this.loadDatas()
    },
    changePage (value) {
      this.params.page = value - 1
      this.loadDatas()
    },
    changeOptions (value) {
      if (this.params.limit !== value.itemsPerPage) {
        this.params.limit = value.itemsPerPage
        this.params.page = value.page - 1
        this.loadDatas()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table-header th{
    background:#e6e6e6 !important;
  }
</style>
