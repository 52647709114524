<template>
  <div id="company-address">
    <v-row class="justify-center">
      <v-col cols="12" sm="12">
        <div class="details-fiche no-p-top" justify="center" align="center">
          <v-snackbar
            v-model="snackbar"
            :bottom="true"
            color="success"
            :timeout="6000"
            class="text-center"
            >
            {{ message }}
          </v-snackbar>
          <CompanyHeader :company="company"/>
          <h3 class="text-right mb-5">
            <AddressModal
              @created="addressCreated"
              @updated="addressUpdated"
              @resetEditingAddress="editingAddress = null"
              :editingAddress="editingAddress"
              :existingOthers="existingOthers"
              />
          </h3>
          <v-simple-table height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">{{ $t('street') }}</th>
                  <th class="text-left">{{ $t('number') }}</th>
                  <th class="text-left">{{ $t('post_code') }}</th>
                  <th class="text-left">{{ $t('city') }}</th>
                  <th class="text-left">{{ $t('other') }}</th>
                  <th class="text-left">{{ $t('country') }}</th>
                  <th class="text-left">{{ $t('actions') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in displayedAddresses" :key="item.id">
                  <td>{{ item.street }}</td>
                  <td>{{ item.number }}</td>
                  <td>{{ item.post_code }}</td>
                  <td>{{ item.city }}</td>
                  <td>{{ item.other }}</td>
                  <td>{{ item.country }}</td>
                  <td>
                    <v-tooltip top open-delay="200">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="#AB650D" dark v-on="on">
                          <v-icon @click="deleteSingleItem(item)">mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t ('delete_address') }}</span>
                    </v-tooltip>
                    <v-tooltip top open-delay="200">
                      <template v-slot:activator="{ on }">
                        <v-btn text icon color="#AB650D" dark v-on="on">
                          <v-icon @click="editSingleItem(item)">mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t ('edit_address') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddressModal from './AddressModal'
import CompanyHeader from './Helpers/CompanyHeader'
export default {
  components: { AddressModal, CompanyHeader },
  props: ['addresses', 'company'],
  data () {
    return {
      message: null,
      snackbar: false,
      lastDeletedItem: null,
      lastDeletedIndice: null,
      editingAddress: null
    }
  },
  computed: {
    existingOthers () {
      const existingOthers = []
      for (const address of this.addresses) {
        if (address.other && !existingOthers.includes(address.other)) {
          existingOthers.push(address.other)
        }
      }
      return existingOthers
    },
    displayedAddresses () {
      return this.filterByOther(this.addresses)
    }
  },
  methods: {
    addressCreated () {
      this.$emit('refresh')
      this.message = this.$t('address_sucessfully_created')
      this.snackbar = true
    },
    addressUpdated () {
      this.$emit('refresh')
      this.message = this.$t('address_sucessfully_updated')
      this.snackbar = true
    },
    deleteSingleItem (item) {
      const shouldDelete = confirm(this.$t('confirm_address_delete'))
      if (!shouldDelete) {
        return
      }
      this.removeItem(item)
      this.$store.dispatch('deleteAddress', item.id)
        .then(response => {
          this.message = this.$t('address_sucessfully_deleted')
          this.snackbar = true
        })
        .catch(() => {
          this.revertLastItemDelete()
        })
    },
    removeItem (item) {
      for (let i = 0; i < this.addresses.length; ++i) {
        if (item.id === this.addresses[i].id) {
          this.lastDeletedIndice = i
          this.lastDeletedItem = item
          this.addresses.splice(i, 1)
        }
      }
    },
    revertLastItemDelete () {
      this.addresses.splice(this.lastDeletedIndice, 0, this.lastDeletedItem)
    },
    editSingleItem (item) {
      this.editingAddress = item
    },
    filterByOther (addresses) {
      addresses.sort((a, b) => {
        let comparison = 0
        if (a.other === 'C') {
          comparison = 1
        } else if (b.other === 'C') {
          comparison = -1
        }
        return comparison
      })
      return addresses
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
