<template>
  <div>
    <h1 v-if="!isSuperAdmin && company">
      <template v-if="isEdoc">
        {{ $t('edocs_management')}}
      </template>
      <template v-else>
        {{ $t('bond_management') }}
      </template>
      : {{ company.name }}
      <span class="company_num_ass company-infos pl-2">
        {{ company.nr_amo }}
      </span>
      <span class="company_address company-infos pl-4">
        <span v-for="(address, i) in company.addresses" :key="company.hash + 'add' + i">
          {{ address.street }}, {{ address.number }} {{ address.post_code }} {{ address.city }} {{ address.country }}
        </span>
      </span>
      <span class="company_info company-infos pl-4">
        {{ company.vat_number }}
      </span>
    </h1>
    <h1 v-else>
      <template v-if="!noDefault">
        <template v-if="isEdoc">
          {{ $t('edocs_management')}}
        </template>
        <template v-else>
          {{ $t('bond_management') }}
        </template>
      </template>
    </h1>
  </div>
</template>

<script>
export default {
  props: ['company', 'isEdoc', 'noDefault'],
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 16px;
}
</style>
