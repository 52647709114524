<template>
<div class="text-center">
  <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on }">
        <v-btn class="align-self-center" color="#AB650D" dark v-on="on" large>
              {{ $t('new') }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ $t('add_edoc') }}
        </v-card-title>
        <v-form
          v-model="form.valid"
          @submit.prevent="validate"
          id="edoc-form"
        >
          <v-card-text>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.company"
              :items="companies"
              item-value="hash"
              item-text="name"
              :label="$t('company')"
              @change="refreshBondList(form.company)"
              required
            ></v-select>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.bonds"
              :items="bonds"
              v-if="form.company"
              item-value="hash"
              item-text="lib_int"
              :label="$t('bond')"
              required
            ></v-select>
            <v-file-input
                :label="$t('linked_document')"
                v-model="form.file"
                :rules="[v => !!v || $t('linked_document_required')]"
                accept="application/pdf"
                required
              ></v-file-input>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!form.valid"
              color="success"
              class="mr-4 mt-8"
              :loading="isLoading"
              x-large
            >
              {{ $t('validate') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['companies'],
  data () {
    return {
      form: {
        valid: false,
        bonds: null,
        company: null,
        file: null
      },
      isLoading: false,
      dialog: null,
      bonds: [],
      snackbar: false
    }
  },
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  },
  methods: {
    validate () {
      if (this.form.valid) {
        this.isLoading = true
        this.$store.dispatch('addEdoc', this.form)
          .then(response => {
            this.$emit('snackbar')
            this.isLoading = false
            this.$emit('refresh')
            this.dialog = false
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    resetForm () {
      this.form = {
        valid: false,
        bonds: null,
        company: null,
        file: null
      }
    },
    refreshBondList (selectedCompany) {
      this.$store.dispatch('fetchBondByCompany', selectedCompany)
        .then(response => {
          this.bonds = response
        })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
