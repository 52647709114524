<template>
  <h2 class="text-left float-left">
    {{ company.name }}
  <span class="company_num_ass company-infos pl-2">{{ company.nr_amo }}</span>
  <span class="company_address company-infos pl-4">{{ company.vat_number }}</span>
  </h2>
</template>

<script>
export default {
  props: ['company']
}
</script>

<style lang="scss" scoped>

</style>
