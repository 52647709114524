import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    fetchUsersFromCompany (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/users/from-company/' + payload, request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchAllUsers (context) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/users/', request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchUser (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/users/' + payload, request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    createUser (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/users', payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateUser (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.put(path + '/api/users/' + payload.userHash, payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    softDeleteUser (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/users/softDelete', { userHash: payload }, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
