<template>
  <div id="tools-export" md="12">
        <v-col cols="3" md="3" class="item-export field-export-all">
            <v-text-field
                v-model="bondId"
                :label="$t('bond_id_since_when_you_want_documents')"
                type="text"
            ></v-text-field>
        </v-col>
        <v-col cols="3" md="3" class="item-export field-export-all">
            <v-text-field
                v-model="bondIdLimit"
                :label="$t('limit_id_for_edoc_dl')"
                type="text"
            ></v-text-field>
        </v-col>
        <v-col cols="5" md="4" class="item-export btn-export-all">
            <v-btn @click="exportFrom" v-on="on">
                {{ $t('download_document')}}
            </v-btn>
        </v-col>
  </div>
</template>

<script>
export default {
  props: [],
  data () {
    return {
      bondId: null,
      bondIdLimit: null
    }
  },
  methods: {
    exportFrom () {
      const payload = {
        bondId: this.bondId,
        bondIdLimit: this.bondIdLimit
      }

      this.$store.dispatch('downloadAllFileSince', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
    #tools-export {
        width: 100%;
    }
    .item-export {
        display: inline-block;
    }
    .item-export.btn-export-all {
        width: 50%;
    }
    .item-export.field-export-all {
        width: 30%;
    }
</style>
