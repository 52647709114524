import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    createLiberationList (context) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/liberations/list', {}, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createMultipleLiberation (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/liberations/multiple', payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchForBond (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        const request = {
          headers: {
            Authorization: token
          }
        }
        axios.get(path + '/api/liberations/for-bond/' + payload, request)
          .then(response => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    deleteLastLiberation (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.delete(path + '/api/liberations/' + payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
