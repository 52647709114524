<template>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          color="info"
          v-on="on"
          class="mx-2"
        >
          mdi-information
        </v-icon>
      </template>
      <span>
        <b>{{ beneficiaire.name }}</b> <br />
        {{ beneficiaire.street }}
        {{ beneficiaire.number }}<br />
        {{ beneficiaire.post_code }}
        {{ beneficiaire.city }}<br />
        {{ beneficiaire.country }}<br />
        {{ beneficiaire.email }}<br />
        {{ beneficiaire.nr_id }}<br />
        ({{ filterDate(beneficiaire.created_at) }})
      </span>
    </v-tooltip>
</template>

<script>
export default {
  props: ['beneficiaireId', 'beneficiaires'],
  computed: {
    beneficiaire () {
      for (const benef of this.beneficiaires) {
        if (benef.id === this.beneficiaireId) {
          return benef
        }
      }
      return { name: null, street: null, number: null, post_code: null, city: null, country: null, created_at: null, email: null, nr_id: null }
    }
  },
  methods: {
    filterDate (value) {
      const date = new Date(value)
      return date.toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
