<template>
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn color="primary" v-on="on" @click="resetForm">{{ $t('new') }}</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
          >
          {{ $t(form && form.id ? 'edit_contact' : 'new_contact') }}
        </v-card-title>
        <v-form
          v-model="form.valid"
          @submit.prevent="validate"
          id="contact-form"
          ref="form"
        >
          <v-card-text>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.type"
              :rules="[v => !!v || $t('type_required')]"
              :items="displayedTypes"
              :label="$t('type')"
              required
            ></v-select>
            <v-text-field
              color="#AB650D"
              v-model="form.content"
              :rules="contentRules"
              :label="$t('value')"
              required
            ></v-text-field>
            <v-text-field
              color="#AB650D"
              v-model="form.comment"
              :rules="commentRules"
              :label="$t('comment')"
              required
            ></v-text-field>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!form.valid"
              color="success"
              class="mr-4 mt-8"
              :loading="isLoading"
              x-large
            >
              {{ $t(form && form.id ? 'update' : 'validate') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>
import Vue from 'vue'
export default {
  props: ['editingContact', 'existingContacts'],
  data () {
    return {
      form: {
        valid: false,
        type: null,
        content: null,
        comment: null,
        id: null
      },
      isLoading: false,
      menu: null,
      dialog: null
    }
  },
  computed: {
    types () {
      return ['TEL', 'FAX', 'EML', 'PSW', 'ADM', 'FIN']
    },
    displayedTypes () {
      const displayedTypes = []
      for (const type of this.types) {
        if (!this.existingContacts.includes(type) || type === this.form.type) {
          displayedTypes.push(type)
        }
      }
      return displayedTypes
    },
    commentRules () {
      return [
        v => (!v || v.length < 255) || this.$t('comment_too_long')
      ]
    },
    contentRules () {
      return [
        v => !!v || this.$t('name_required'),
        v => (v && v.length < 255) || this.$t('value_too_long')
      ]
    }
  },
  methods: {
    validate () {
      if (!this.form.id) {
        this.createNewContact()
      } else {
        this.updateContact()
      }
    },
    createNewContact () {
      if (this.form.valid) {
        this.form.company = { hash: this.$route.params.hash }
        this.isLoading = true
        this.$store.dispatch('createContact', this.form)
          .then(response => {
            this.isLoading = false
            this.dialog = false
            this.$emit('created')
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    updateContact () {
      if (this.form.valid) {
        this.isLoading = true
        this.$store.dispatch('editContact', this.form)
          .then(response => {
            this.isLoading = false
            this.dialog = false
            this.$emit('updated')
            this.resetForm()
          })
          .catch(() => {
            this.isLoading = false
          })
      }
    },
    resetForm () {
      this.form = {
        valid: false,
        type: null,
        content: null,
        comment: null,
        id: null
      }
      Vue.nextTick(() => {
        this.$refs.form.reset()
      })
    }
  },
  watch: {
    editingContact (newValue) {
      if (!newValue) {
        return
      }
      this.form.type = newValue.type
      this.form.content = newValue.content
      this.form.comment = newValue.comment
      this.form.id = newValue.id
      this.dialog = true
      this.$emit('resetEditingContact')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
