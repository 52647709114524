import { store } from '../store/index.js'

async function adminGuard (to, from, next) {
  const token = store.getters.getToken
  if (token) {
    const isAdmin = store.getters.isAdmin
    const isSuperAdmin = store.getters.isSuperAdmin
    if (isAdmin || isSuperAdmin) {
      next()
    } else {
      next('/')
    }
  } else {
    const auth = await store.dispatch('initAuth')
    if (auth) {
      const isAdmin = store.getters.isAdmin
      const isSuperAdmin = store.getters.isSuperAdmin
      if (isAdmin || isSuperAdmin) {
        next()
      } else {
        next('/')
      }
    } else {
      next('/login')
    }
  }
}

export default adminGuard
