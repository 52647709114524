<template>
  <td colspan="12" class="drop-bg-td">
    <div class="drop-td pa-4">
      <v-row>
        <v-col cols="12" md="4">
          <p><b>{{ $t('beneficiaires') }}</b></p>
            <div v-for="benef in item.beneficiaires" :key="benef.id" class="benef-box">
              <p class=" font-weight-bold">{{ benef.beneficiaire.name }}</p>
              <p class="">
                <span>{{ benef.beneficiaire.street }}</span>
                , <span>{{ benef.beneficiaire.number }}</span>
              </p>
              <p class="" v-if="benef.beneficiaire.post_code || benef.beneficiaire.city || benef.beneficiaire.country">
                <span class="">{{ benef.beneficiaire.post_code }}</span>
                <span class="px-1">{{ benef.beneficiaire.city }}</span><br />
                <span class="">{{ $t(benef.beneficiaire.country) }}</span>
              </p>
            </div>
        </v-col>
        <v-col cols="12" md="8">
          <p class="text-center" v-if="item.liberations.length > 0"><b>{{ $t('liberations')}}</b></p>
          <p class="text-right" v-for="lib in item.liberations" :key="lib.id">
            <span class="px-5" v-if="lib.is_fully_released"><v-icon>mdi-lock</v-icon></span>
            <span class="px-5" v-else><v-icon>mdi-lock-open-outline</v-icon></span>
            <span class="px-5"><b class="monospace">{{ lib.date_lib | formatDate }}</b></span>
            <span class="px-5 monospace">{{ lib.amount | formatPrice }} €</span>
          </p>
        </v-col>
      </v-row>
    </div>
  </td>
</template>

<script>
export default {
  props: ['item', 'headers']
}
</script>

<style lang="scss" scoped>
.benef-box {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.benef-box p {
  padding: 2px 0px;
  margin: 2px 0px;
}
</style>
