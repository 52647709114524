<template>
  <div id="user-form">
    <v-row class="justify-center">
      <v-col cols="12" sm="6">
        <div class="details-fiche elevation-4" justify="center" align="center">
          <v-form
            v-model="form.valid"
            @submit.prevent="validate"
            id="user-form"
            >
            <v-text-field
              color="#AB650D"
              v-model="form.name"
              :rules="usernameRules"
              :label="$t('username')"
              required
            ></v-text-field>
            <v-text-field
              color="#AB650D"
              v-model="form.email"
              :rules="emailRules"
              :label="$t('email')"
              required
            ></v-text-field>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.roles"
              :rules="[v => !!v || $t('role_needed')]"
              :items="roles"
              :label="$t('role')"
              required
            ></v-select>
            <v-select
              color="#AB650D"
              item-color="brown"
              v-model="form.status"
              :rules="[v => (v === 0 || v === 1) || $t('status_needed')]"
              :items="status"
              :label="$t('status')"
              required
            ></v-select>
            <v-autocomplete
              v-if="isSuperAdmin"
              v-model="form.companies"
              :items="companies"
              :loading="areCompaniesLoading"
              chips
              small-chips
              :label="$t('companies')"
              multiple
              item-value="hash"
              item-text="name"
            ></v-autocomplete>
            <v-btn
              type="submit"
              :disabled="!form.valid"
              color="success"
              class="mr-4 mt-8"
              :loading="isLoading"
              x-large
            >
              {{ $t('validate') }}
            </v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['form', 'isLoading', 'isUpdating'],
  data () {
    return {
      emailRules: [
        (v) => !!v || this.$t('email_required'),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || this.$t('email_must_be_valid')
      ],
      companies: null,
      areCompaniesLoading: false
    }
  },
  created () {
    if (this.isSuperAdmin) {
      this.areCompaniesLoading = true
      this.$store.dispatch('fetchCompaniesForForms')
        .then(response => {
          this.companies = response
          this.areCompaniesLoading = false
        })
        .catch(() => {
          this.areCompaniesLoading = false
        })
    }
  },
  computed: {
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    },
    roles () {
      if (!this.isSuperAdmin) {
        return [
          { value: 'ROLE_USER', text: this.$t('ROLE_USER') },
          { value: 'ROLE_USER_READONLY', text: this.$t('ROLE_USER_READONLY') },
          { value: 'ROLE_ADMIN', text: this.$t('ROLE_ADMIN') }
        ]
      } else {
        return [
          { value: 'ROLE_USER', text: this.$t('ROLE_USER') },
          { value: 'ROLE_USER_READONLY', text: this.$t('ROLE_USER_READONLY') },
          { value: 'ROLE_ADMIN', text: this.$t('ROLE_ADMIN') },
          { value: 'ROLE_SUPERADMIN', text: this.$t('ROLE_SUPERADMIN') }
        ]
      }
    },
    status () {
      return [
        { value: 1, text: this.$t('active') },
        { value: 0, text: this.$t('inactive') }
      ]
    },
    usernameRules () {
      return [
        v => !!v || this.$t('username_required'),
        v => (v && v.length < 256) || this.$t('username_too_long')
      ]
    }
  },
  methods: {
    validate () {
      this.$emit('validate')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
