<template>
<div class="text-center">
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">mdi-cash-usd-outline</v-icon>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ $t('manage_liberations') }}
        </v-card-title>
        <v-simple-table class="mt-6" v-if="!isFetchLoading">
          <template v-slot:default>
            <tbody>
              <tr v-for="liberation in liberations" :key="liberation.hash">
                <td v-if="liberation.is_fully_released"><v-icon>mdi-lock</v-icon></td>
                <td v-else><v-icon>mdi-lock-open-outline</v-icon></td>
                <td><b>{{ liberation.date_lib | formatDate }}</b></td>
                <td>{{ liberation.amount }} {{ bond.devise }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center" v-else>
          <v-skeleton-loader
            class="mx-auto mt-3"
            max-width="100%"
            type="article"
            elevation="4"
          ></v-skeleton-loader>
          </div>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="liberations.length > 0"
            color="error"
            @click="deleteLastLiberation"
            :loading="isDeleteLoading"
            class="my-4"
            >
            {{ $t('delete_last_liberation') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['bond'],
  data () {
    return {
      isDeleteLoading: false,
      isFetchLoading: false,
      dialog: null,
      liberations: []
    }
  },
  computed: {
  },
  methods: {
    loadLiberations () {
      this.isFetchLoading = true
      this.$store.dispatch('fetchForBond', this.bond.hash)
        .then(response => {
          this.liberations = response
          this.isFetchLoading = false
        })
        .catch(() => {
          this.isFetchLoading = false
        })
    },
    deleteLastLiberation () {
      const lastLiberationId = this.liberations[this.liberations.length - 1].id
      this.isDeleteLoading = true
      this.$store.dispatch('deleteLastLiberation', lastLiberationId)
        .then(response => {
          this.isDeleteLoading = false
          this.dialog = false
          this.$emit('refresh')
        })
        .catch(() => {
          this.isDeleteLoading = false
        })
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.loadLiberations()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
