<template>
  <div class="" justify="center" align="center" id="pdfContainer">
    <div v-if="files.length === 0" class="details-fiche elevation-4">
      {{ $t('file_here') }}
    </div>
    <div class="d-flex justify-center">
      <v-btn
        small
        class="mx-2"
        v-for="(file, index) in files"
        :key="file" color="primary"
        @click="changeCurrentFile(index)"
        >
        <v-icon>mdi-paperclip</v-icon>
        {{ $t('doc' + filesType[index]) }}
      </v-btn>
    </div>
    <div class="d-flex justify-center pt-2">
    </div>
    <iframe :src="file" id="pdf_frame" v-if="file"></iframe>
  </div>
</template>

<script>
export default {
  props: ['files', 'filesType'],
  data () {
    return {
      currentFile: 0
    }
  },
  computed: {
    file () {
      return this.files[this.currentFile]
    }
  },
  methods: {
    changeCurrentFile (index) {
      this.currentFile = index
      this.resetSearchAndRotation()
    }
  }
}
</script>

<style lang="scss" scoped>
#pdf_frame {
  height: 70vh;
  width: 100%;
}
</style>
