var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5",attrs:{"id":"bond-datatable"}},[_c('v-snackbar',{attrs:{"bottom":true,"color":"success","timeout":6000},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.successAlert)+" ")]),_c('div',{staticClass:"d-flex justify-space-between align-start",attrs:{"id":"select_box"}},[_c('v-text-field',{staticClass:"ml-3",staticStyle:{"max-width":"350px"},attrs:{"label":_vm.$t('search_for_an_edoc'),"single-line":"","hide-details":"","color":"#AB650D"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-compa",attrs:{"headers":_vm.headers,"items":_vm.filteredItems,"search":_vm.search,"fixed-header":""},scopedSlots:_vm._u([{key:"item.is_read",fn:function(ref){
var item = ref.item;
return [(item.is_read)?_c('v-icon',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v(" mdi-email-open-outline ")]):_c('v-icon',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v(" mdi-email-receive ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('doc' + item.type))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.downloadItem(item)}}},[_vm._v("mdi-download")])],1),(!_vm.isReadOnlyUser)?_c('v-btn',{attrs:{"text":"","icon":"","color":"#AB650D","dark":""}},[_c('v-icon',{on:{"click":function($event){return _vm.softDelete(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"type":"error"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t('no_results_for'))+" \""+_vm._s(_vm.search)+"\" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }