<template>
  <v-container grid-list-xl text-xs-center style="height:100%">
    <v-layout class="d-flex flex-column justify-center align-center"  row wrap justify-center style="height:100%">
      <h1 class="text-center title-login">{{ $t('login_title') }}</h1>
      <v-flex class="col-sm-5 col-xs-12 d-flex flex-column justify-center align-center"  style="height:450px;">
        <div class=" elevation-3 login-box" style="background:#fff;padding:24px;width:100%;">
          <v-alert color="error" icon="mdi-error" type="error" dismissible v-model="hasError">
          {{ $t(errorMessage) }}
        </v-alert>
        <v-form v-model="valid" @submit.prevent="login" id="login" style="width:100%">
          <v-text-field
            id="form-email"
            :label="$t('email')"
            type="email"
            v-model="mail"
            :rules="mailRules"
            :counter="200"
            color="#AB650D"
            required
            class="mb-3"
          ></v-text-field>
          <v-text-field
            id="form-password"
            :label="$t('password')"
            type="password"
            v-model="password"
            :rules="passwordRules"
            required
            color="#AB650D"
            class="mb-5"
            ></v-text-field>
            <v-btn type="submit" :loading="isLoading" :disabled="!valid" :block="true" color="#AB650D" :large="true" class="btn-wd" id="login-btn">{{ $t('login') }}</v-btn>
        </v-form>
        <div class="text-center mt-5">
          <v-btn :to="{ name: 'lost-password' }" color="#4a472f" tag="v-btn" :rounded="true" :outlined="true">{{ $t('forgot_password') }}</v-btn>
        </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      valid: false,
      hasError: false,
      errorMessage: '',
      mail: '',
      mailRules: [
        (v) => !!v || this.$t('email_required'),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || this.$t('email_must_be_valid')
      ],
      password: '',
      passwordRules: [
        (v) => !!v || this.$t('password_required')
      ],
      isLoading: false
    }
  },
  methods: {
    login () {
      if (this.valid) {
        this.isLoading = true
        this.$store.dispatch('authenticate', { password: this.password, username: this.mail })
          .then(response => {
            this.isLoading = false
            if (this.$store.getters.isSuperAdmin) {
              this.$router.push('/bond')
            } else {
              this.$router.push('/')
            }
          })
          .catch(() => {
            this.isLoading = false
            this.hasError = true
            this.errorMessage = 'bad_password'
          })
      }
    },
    forgotPass () {
      alert("Veuillez contacter l'administrateur ou un responsable.")
    }
  }
}
</script>

<style scoped>
p {
  margin-top: 40px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
