<template>
  <v-container
  class="px-7"
  fluid
  id="home"
  >
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      color="success"
      :timeout="6000"
      >
      {{ successAlert }}
    </v-snackbar>
    <v-row v-if="isSuperAdmin">
      <v-col cols="12">
        <v-btn :to="{ name: 'bond-management' }" class="back-btn mb-5" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      class="justify-start tableau_company elevation-4 pa-4"
    >
      <v-col cols="12" class="text-left">
        <v-skeleton-loader
          class="mx-auto"
          v-if="!edocs"
          max-width="100%"
          type="article"
          elevation="4"
        ></v-skeleton-loader>
        <div v-if="edocs">
          <CompanyInfo :company="company" :isEdoc="true" />
          <Datatable :edocs="edocs" :companies="companies" @reload="initialize" v-if="!isSuperAdmin"/>
          <AdminDatatable :edocs="edocs" :params="params" @reload="initialize" v-else/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Datatable from './Datatable'
import AdminDatatable from './AdminDatatable'
import CompanyInfo from '../CompanyInfo'
export default {
  name: 'edocs-list',
  components: {
    Datatable,
    AdminDatatable,
    CompanyInfo
  },
  data () {
    return {
      hasError: false,
      errorMessage: null,
      successAlert: null,
      snackbar: false,
      params: {
        page: 0,
        limit: 10,
        search: '',
        companyHash: null
      }
    }
  },
  computed: {
    company () {
      const companies = this.$store.getters.getCompanies
      const companyHash = this.$store.getters.getCurrentCompanyHash

      if (companies) {
        for (let i = 0; i < companies.length; ++i) {
          if (companies[i].hash === companyHash) {
            return companies[i]
          }
        }
      }

      return null
    },
    edocs () {
      return this.$store.getters.getEdocs
    },
    companies () {
      if (!this.edocs) {
        return []
      }
      let companies = []
      if (this.isSuperAdmin) {
        companies = [this.$t('all_companies')]
      }
      let company
      for (let i = 0; i < this.edocs.length; ++i) {
        company = this.edocs[i].company
        if (!companies.includes(company)) {
          companies.push(company)
        }
      }
      return companies
    },
    getCurrentCompanyHash () {
      return this.$store.getters.getCurrentCompanyHash
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  },
  created () {
    if (this.$store.getters.getShouldReloadData) {
      this.initialize()
      this.$store.commit('setShouldReloadData', false)
    }
  },
  activated () {
  },
  methods: {
    initialize () {
      if (this.isSuperAdmin) {
        this.loadAllEdocs()
      } else {
        if (this.$store.getters.getCurrentCompanyHash) {
          this.loadEdocsFromCompany()
        }
      }
    },
    loadEdocsFromCompany () {
      this.$store.dispatch('fetchEdocsFromCompany', this.getCurrentCompanyHash)
    },
    loadAllEdocs () {
      this.$store.dispatch('fetchAdminEdocs', this.params)
    }
  }
}
</script>
<style scoped>
</style>
