import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
    token: null,
    tokenCreation: null,
    refreshToken: null,
    role: null,
    name: null,
    companies: null,
    currentCompanyHash: null,
    shouldReloadData: true
  },
  getters: {
    getToken (state) {
      return state.token
    },
    getRefreshToken (state) {
      return state.refreshToken
    },
    getRole (state) {
      return state.role
    },
    getName (state) {
      return state.name
    },
    getCompanies (state) {
      return state.companies
    },
    getCurrentCompanyHash (state) {
      return state.currentCompanyHash
    },
    isAdmin (state) {
      if (!state.role) {
        return false
      }
      return state.role.includes('ROLE_ADMIN')
    },
    isSuperAdmin (state) {
      if (!state.role) {
        return false
      }
      return state.role.includes('ROLE_SUPERADMIN')
    },
    isReadOnlyUser (state) {
      if (!state.role) {
        return false
      }
      return state.role.includes('ROLE_USER_READONLY')
    },
    getShouldReloadData (state) {
      return state.shouldReloadData
    }
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
      state.tokenCreation = Date.now()
      localStorage.setItem('accessToken', payload)
    },
    setRefreshToken (state, payload) {
      state.refreshToken = payload
      localStorage.setItem('refreshToken', payload)
    },
    logout (state) {
      state.token = null
      state.refreshToken = null
      state.tokenCreation = null
      state.companies = null
      state.currentCompanyHash = null
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('role')
      localStorage.removeItem('companies')
    },
    setRole (state, payload) {
      state.role = payload
      localStorage.setItem('role', payload)
    },
    setName (state, payload) {
      state.name = payload
      localStorage.setItem('name', payload)
    },
    setCompanies (state, payload) {
      state.companies = payload
      localStorage.setItem('companies', JSON.stringify(payload))
    },
    setCurrentCompanyHash (state, payload) {
      state.currentCompanyHash = payload
      if (payload) {
        localStorage.setItem('currentCompanyHash', payload)
      } else {
        localStorage.removeItem('currentCompanyHash')
      }
    },
    setShouldReloadData (state, payload) {
      state.shouldReloadData = payload
    }
  },
  actions: {
    async initAuth (context) {
      const refreshToken = localStorage.getItem('refreshToken')
      context.commit('setRole', localStorage.getItem('role'))
      context.commit('setName', localStorage.getItem('name'))
      const companies = localStorage.getItem('companies') ? JSON.parse(localStorage.getItem('companies')) : null
      context.commit('setCompanies', companies)
      context.commit('setCurrentCompanyHash', localStorage.getItem('currentCompanyHash'))

      if (!refreshToken) {
        return
      }
      context.commit('setRefreshToken', refreshToken)
      const auth = await context.dispatch('refreshToken', refreshToken)
      if (auth) {
        context.dispatch('initPeriodicallyRefreshToken')
      }
      return auth
    },
    refreshToken (context, payload) {
      const refreshToken = payload
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/token/refresh', {
          refresh_token: refreshToken
        })
          .then(response => {
            const token = response.data.token
            context.commit('setToken', token)
            resolve(true)
          })
          .catch(() => {
            resolve(null)
          })
      })
    },
    authenticate (context, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          username: payload.username,
          password: payload.password
        }
        axios.post(path + '/api/login_check', data)
          .then(response => {
            context.commit('setToken', response.data.token)
            context.commit('setRefreshToken', response.data.refresh_token)
            context.commit('setRole', response.data.roles[0])
            context.commit('setName', response.data.name)
            context.commit('setCompanies', response.data.companies)
            context.dispatch('initCurrentCompany')
            context.dispatch('initPeriodicallyRefreshToken')
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    initCurrentCompany (context) {
      const currentCompanyHash = localStorage.getItem('currentCompanyHash')
      const companies = context.getters.getCompanies
      const companiesHash = companies.map(x => x.hash)
      if (!currentCompanyHash) {
        context.commit('setCurrentCompanyHash', companiesHash[0])
      }
      if (companiesHash.includes(currentCompanyHash)) {
        context.commit('setCurrentCompanyHash', currentCompanyHash)
      } else {
        context.commit('setCurrentCompanyHash', companiesHash[0])
      }
    },
    initPeriodicallyRefreshToken (context) {
      setTimeout(() => {
        const refreshToken = context.getters.getRefreshToken
        if (refreshToken) {
          context.dispatch('refreshToken', refreshToken)
            .then((response) => {
              if (response) {
                context.dispatch('initPeriodicallyRefreshToken')
              }
            })
        }
      }, 600000)
    },
    lostpassword (context, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          email: payload.email
        }
        axios.post(path + '/api/users/lost-password', data)
          .then(response => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    checkLostToken (context, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          lostToken: payload.lostToken
        }
        axios.post(path + '/api/users/lost-password-check-token', data)
          .then(response => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    setPassword (context, payload) {
      return new Promise((resolve, reject) => {
        const data = {
          password: payload.password,
          lostToken: payload.lostToken
        }
        axios.post(path + '/api/users/set-password', data)
          .then(response => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
