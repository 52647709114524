import axios from 'axios'
const path = process.env.VUE_APP_URLAPI

export default {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    createAddress (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.post(path + '/api/addresses', payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editAddress (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.put(path + '/api/addresses/' + payload.id, payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteAddress (context, payload) {
      const token = 'Bearer ' + context.getters.getToken
      return new Promise((resolve, reject) => {
        axios.delete(path + '/api/addresses/' + payload, {
          headers: {
            Authorization: token
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
