<template>
  <div id="bond-datatable" class="px-5">
    <div id="select_box" class="d-flex justify-space-between align-start">
      <v-autocomplete
        v-model="params.companyHash"
        :loading="areCompaniesLoading"
        :items="companies"
        style="max-width:350px"
        class="ml-3"
        color="#AB650D"
        item-color="brown"
        @change="changeCompany"
        item-value="hash"
        item-text="name"
        clearable
        no-data-text="Rien"
        >
      </v-autocomplete>
      <v-select
        v-model="params.filter_status"
        :items="stateStatus"
        style="max-width:350px"
        class="ml-3"
        color="#AB650D"
        item-color="brown"
        @change="paramsChanged"
        >
      </v-select>
      <v-text-field
          v-model="params.search"
          :label="$t('search_for_a_bond')"
          single-line
          hide-details
          style="max-width:350px"
          class="ml-3"
          color="#AB650D"
          @input="paramsChanged"
        ></v-text-field>
        <v-btn
          :to="{ name: 'new-bond' }"
          class="align-self-center"
          color="#AB650D"
          dark
          large
          >
          {{ $t('new') }}
        </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      fixed-header
      class="table-compa"
      :loading="isCompanyLoading"
      @update:page="changePage"
      @update:options="changeOptions"
      :server-items-length="parseInt(total,10)"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      :options="{
        'itemsPerPage': 50
      }"
      :expanded.sync="expanded"
      show-expand
      >
      <template v-slot:item.export_flag="{ item }">
        <v-tooltip top open-delay="200" v-if="item.export_flag === 1">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text icon color="#000" dark>
              <v-icon>mdi-alert-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_open_request') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200" v-if="item.export_flag === 2 && !item.nr_acte">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text icon color="#000" dark>
              <v-icon v-on="on">mdi-account-lock</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_closed_request') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.id="{ item }">
        R{{ item.id }}
      </template>
      <template v-slot:item.montant_devise="{ item }">
        <span class="nowrap">
          {{ item.montant_devise | formatPrice }}
        </span>
      </template>
      <template v-slot:item.date_acte="{ item }">
        {{ item.date_acte | formatDate }}
      </template>
      <template v-slot:item.montant_initial="{ item }">
        <span class="nowrap">
          {{ item.montant_initial | formatPrice }}
        </span>
      </template>
      <template v-slot:item.montant_engage="{ item }">
        <span class="nowrap">
          {{ item.montant_engage | formatPrice }}
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
            <v-btn text icon color="#AB650D" dark v-on="on">
              <v-icon @click="seeSingleItem(item)">mdi-page-next-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t ('tooltip_action_resume') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200" v-if="shouldAllowProcessing(item)">
          <template v-slot:activator="{ on }">
          <v-btn text icon color="#AB650D" dark  v-on="on">
            <v-icon @click="processSingleItem(item)">mdi-circle-edit-outline</v-icon>
          </v-btn>
          </template>
          <span>{{ $t ('tooltip_action_pdfform') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
          <v-btn text icon color="#AB650D" dark  v-on="on">
            <v-icon @click="downloadFiles(item)">mdi-download-outline</v-icon>
          </v-btn>
          </template>
          <span>{{ $t ('tooltip_action_dl_pdf') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200" v-if="shouldDisplayLiberation(item)">
          <template v-slot:activator="{ on }">
          <v-btn text icon color="#AB650D" dark v-on="on">
            <LiberationModal :bond="item" @refresh="refresh"/>
          </v-btn>
          </template>
          <span>{{ $t('manage_liberations') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200">
          <template v-slot:activator="{ on }">
          <v-btn text icon color="#AB650D" dark  v-on="on">
            <v-icon class="comp" @click="complementary(item)">mdi-wallet-plus</v-icon>
          </v-btn>
          </template>
          <span>{{ $t('tooltip_create_complementary') }}</span>
        </v-tooltip>
        <v-tooltip top open-delay="200" v-if="item.nr_acte === 'MF'">
          <template v-slot:activator="{ on }">
          <v-btn text icon color="#AB650D" dark  v-on="on">
            <FormalizeModal :bond="item" @refresh="refresh"/>
          </v-btn>
          </template>
          <span>{{ $t('formalize_acte') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <BondDropdown :item="item" :headers="headers" />
      </template>
      <v-alert slot="no-results" :value="true" type="error">
        {{ $t('no_results_for') }} "{{ params.search }}"
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import LiberationModal from './LiberationModal'
import FormalizeModal from './FormalizeModal'
import BondDropdown from './BondDropdown'
export default {
  components: {
    LiberationModal,
    BondDropdown,
    FormalizeModal
  },
  name: 'Datatable',
  props: ['bonds', 'params'],
  data () {
    return {
      selectedCompany: this.$t('all_companies'),
      companies: [],
      areCompaniesLoading: false,
      expanded: []
    }
  },
  filters: {
  },
  computed: {
    total () {
      if (this.bonds && this.bonds.total) {
        return this.bonds.total
      }
      return 0
    },
    stateStatus () {
      return [
        { value: 1, text: this.$t('applications') },
        { value: 2, text: this.$t('balance_gt_0') },
        { value: 3, text: this.$t('balance_equal_0') },
        { value: 4, text: this.$t('every_type_of_balance') }
      ]
    },
    isCompanyLoading () {
      return this.$store.getters.getIsCompanyLoading
    },
    headers () {
      if (this.params.filter_status === 1) {
        return [
          { text: this.$t('status'), align: 'center', value: 'export_flag', sortable: false },
          { text: this.$t('date_saisie_dec'), align: 'center', value: 'created_at', sortable: false },
          { text: this.$t('company'), align: 'left', value: 'company.name', sortable: false },
          { text: this.$t('num'), align: 'left', value: 'id', sortable: false },
          { text: this.$t('montant_devise'), align: 'right', value: 'montant_devise', sortable: false },
          { text: this.$t('montant_initial'), align: 'right', value: 'montant_initial', sortable: false },
          { text: this.$t('balance_eur'), align: 'right', value: 'montant_engage', sortable: false },
          { text: this.$t('ref_ext'), value: 'reference_externe', sortable: false },
          { text: this.$t('works_wording'), value: 'lib_int', sortable: false },
          { text: this.$t('actions'), value: 'action', sortable: false }
        ]
      }
      return [
        { text: this.$t('date_saisie_dec'), align: 'center', value: 'created_at', sortable: false },
        { text: this.$t('company'), align: 'left', value: 'company.name', sortable: false },
        { text: this.$t('nb_bond'), align: 'left', value: 'nr_acte', sortable: false },
        { text: this.$t('bond_date'), value: 'date_acte', sortable: false },
        { text: this.$t('montant_initial'), align: 'right', value: 'montant_initial', sortable: false },
        { text: this.$t('balance_eur'), align: 'right', value: 'montant_engage', sortable: false },
        { text: this.$t('ref_ext'), value: 'reference_externe', sortable: false },
        { text: this.$t('works_wording'), value: 'lib_int', sortable: false },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ]
    },
    filteredItems () {
      if (!this.bonds || !this.bonds.bonds) {
        return []
      }
      return this.bonds.bonds
    }
  },
  created () {
    this.areCompaniesLoading = true
    this.$store.dispatch('fetchCompaniesForForms')
      .then(response => {
        this.companies = response
        this.companies.push({ name: this.$t('all_companies'), hash: null })
        this.areCompaniesLoading = false
      })
      .catch(() => {
        this.areCompaniesLoading = false
      })
  },
  methods: {
    updateCompanyHash (company) {
      this.$store.commit('setCurrentCompanyHash', company)
    },
    seeSingleItem (item) {
      this.$router.push({ name: 'bond-detail', params: { hash: item.hash } })
    },
    processSingleItem (item) {
      this.$router.push({ name: 'process-bond', params: { hash: item.hash } })
    },
    downloadFiles (item) {
      this.$store.dispatch('downloadAllFile', item)
    },
    complementary (item) {
      this.$router.push({
        name: 'new-bond-complementary',
        params: {
          bondHash: item.hash,
          companyHash: item.company.hash
        }
      })
    },
    refresh () {
      this.$emit('refresh')
    },
    loadDatas () {
      this.$store.dispatch('fetchAdminBonds', this.params)
    },
    shouldDisplayLiberation (item) {
      return (item.nr_acte && item.liberations.length > 0)
    },
    shouldAllowProcessing (item) {
      if (item.nr_acte) {
        return false
      }
      return true
    },
    changeCompany () {
      this.paramsChanged()
      this.updateCompanyHash(this.params.companyHash)
    },
    paramsChanged () {
      this.params.page = 0
      this.loadDatas()
    },
    changePage (value) {
      this.params.page = value - 1
      this.loadDatas()
    },
    changeOptions (value) {
      if (this.params.limit !== value.itemsPerPage) {
        this.params.limit = value.itemsPerPage
        this.params.page = value.page - 1
        this.loadDatas()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-data-table-header th{
    background:#e6e6e6 !important;
  }
</style>
