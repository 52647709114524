<template>
<div class="text-center">
  <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-icon v-on="on">mdi-file-certificate</v-icon>
      </template>

      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ $t('formalize_acte') }}
        </v-card-title>
        <v-form
          v-model="form.valid"
          @submit.prevent="validate"
          id="form"
          ref="form"
        >
          <v-card-text>
            <v-text-field
              color="#AB650D"
              v-model="form.nr_acte"
              :rules="nrActeRules"
              :label="$t('nr_acte')"
              ref="input"
              required
            ></v-text-field>
          </v-card-text>

          <v-alert
            type="warning"
            text
            v-if="form.nr_acte && parseInt(form.nr_acte) !== bond.nr_caut"
            >
            {{ $t('nr_acte_not_equal_caut') }}
          </v-alert>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              :disabled="!form.valid"
              color="success"
              class="mr-4 mt-8"
              :loading="isLoading"
              x-large
            >
              {{ $t('formalize') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ['bond'],
  data () {
    return {
      isLoading: false,
      dialog: null,
      form: {
        valid: false,
        nr_acte: null
      }
    }
  },
  created () {
    this.useNrCaut()
  },
  computed: {
    nrActeRules () {
      return [
        v => !!v || this.$t('nr_acte_required'),
        v => (v && v.length < 11) || this.$t('nr_acte_too_long')
      ]
    }
  },
  methods: {
    useNrCaut () {
      this.form.nr_acte = this.bond.nr_caut.toString()
    },
    selectInput () {
      setTimeout(() => {
        this.$refs.input.$el.querySelector('input').select()
      }, 0)
    },
    validate () {
      this.isLoading = true
      this.$store.dispatch('formalizeActe', { hash: this.bond.hash, nr_acte: this.form.nr_acte })
        .then(response => {
          this.isLoading = false
          this.$emit('refresh')
          this.dialog = false
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  },
  watch: {
    dialog () {
      this.selectInput()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
