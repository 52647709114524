/**
 * If a beneficiaire has a street value set to null it means that it comes from a loi breyne bond
 * A beneficiaire coming from a loi breyne cannot be selected, so we need to hide it
 */
export default (beneficiaires) => {
  const b = beneficiaires
  const filteredBeneficiaires = []
  let date
  for (let i = 0; i < b.length; ++i) {
    if (b[i].street) {
      date = new Date(b[i].created_at)
      b[i].label = b[i].name + ' (' + date.toLocaleString() + ')'
      if (b[i].nr_id) {
        b[i].label += ' [' + b[i].nr_id + ']'
      }
      filteredBeneficiaires.push(b[i])
    }
  }
  return filteredBeneficiaires
}
