<template>
  <v-container grid-list-xl text-xs-center style="height:100%">
    <v-layout class="d-flex flex-column justify-center align-center"  row wrap justify-center style="height:100%">
      <h1 class="text-center title-login">{{ $t('generate_new_password') }}</h1>
      <v-flex class="col-sm-5 col-xs-12 d-flex flex-column justify-center align-center"  style="height:450px;">
        <div class=" elevation-3 login-box" style="background:#fff;padding:24px;width:100%;">
          <v-alert type="error" dismissible v-model="hasError">
            {{ $t(errorMessage) }}
          </v-alert>
          <v-alert type="success" dismissible v-model="hasSucceed">
            {{ $t('email_sent_to_reinitialize_password') }}
          </v-alert>
          <v-form v-model="valid" @submit.prevent="submit" id="new-password">
            <v-text-field
              :label="$t('email')"
              type="email"
              v-model="mail"
              :rules="mailRules"
              color="#AB650D"
              :counter="200"
              required
              class="my-5"
            ></v-text-field>
              <v-btn type="submit" :loading="isLoading" :disabled="!valid" class="btn-wd" :large="true" :block="true" color="#AB650D">{{ $t('submit') }}</v-btn>
          </v-form>
          <div class="text-center mt-5">
            <v-btn :to="{ name: 'login' }" color="#4a472f" tag="v-btn" :rounded="true" :outlined="true">{{ $t('login') }}</v-btn>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      valid: false,
      hasError: false,
      errorMessage: '',
      mail: '',
      mailRules: [
        (v) => !!v || this.$t('email_required'),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) || this.$t('email_must_be_valid')
      ],
      isLoading: false,
      hasSucceed: false
    }
  },
  methods: {
    submit () {
      if (this.valid) {
        this.isLoading = true
        this.$store.dispatch('lostpassword', { email: this.mail })
          .then(response => {
            this.isLoading = false
            this.hasSucceed = true
          })
          .catch(() => {
            this.isLoading = false
            this.hasError = true
            this.errorMessage = 'email_doesnt_exist'
          })
      }
    }
  }
}
</script>

<style scoped>
p {
  margin-top: 40px;
  cursor: pointer;
  text-decoration: underline;
}
</style>
