<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      disable-resize-watcher
      app
      right
      temporary
    >
      <Menu />
    </v-navigation-drawer>

    <v-app-bar
      app
      color="white"
      white
      class="elevation-4"
    >
      <v-img
        :src="require('@/assets/fideris-logo.png')"
        contain
        lazy-src
        height="40"
        position="center left"
        width="100"
        ></v-img>
      <v-spacer></v-spacer>
      <CompanySelection v-if="isConnected && !isSuperAdmin" class="select_company" />
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>

    <v-content style="padding:0">
      <keep-alive include="home">
        <router-view></router-view>
      </keep-alive>
    </v-content>
  </v-app>
</template>

<script>
import Menu from './components/Menu'
import CompanySelection from './components/Helpers/CompanySelection'
export default {
  components: {
    Menu,
    CompanySelection
  },
  props: {
    source: String
  },
  data: () => ({
    drawer: false
  }),
  created () {
  },
  methods: {
  },
  computed: {
    isConnected () {
      if (this.$store.getters.getToken) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      return this.$store.getters.isSuperAdmin
    }
  },
  filters: {
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
*{
  font-family: 'Montserrat', sans-serif;
  // color:#4a4a30;
}
body{
  background: #FAFAFA;
}
.btn-wd span{
  color: #fff;
}
.login-box{
  border-radius: 10px;
}
.title-login{
  font-size: 2.5rem;
  line-height: 3rem;
  color: #4a4a30;
  font-weight: 900;
}
#home,#bond-detail,#bond-creation,#bond-update,#bond-processing,#user-creation,#user-update,#company-creation,#company-update,#liberation-table{
  padding-top: 80px;
}
#tools_company{
  .number_company{
    font-weight: 300;
    font-size: 18px;
  }
  .tools{
    &:hover{
      color:#AB650D;
    }
  }
}
#bond-datatable{
  background: #FAFAFA;
  border-radius: 10px;
  th,td,.v-data-footer,.v-data-table{
    background: #FAFAFA;
  }
  tr{
    &:hover{
      th,td{
        background: #ececec;
      }
    }
  }
}
.tableau_company{
  background: #fff;
  border-radius: 5px;
}
.details-fiche{
  max-width: 100%;
  background: #fff;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
}
.logout-menu{
  position:absolute;
  bottom:75px;
  left: 0;
  right: 0;
}
.copyright{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 25px;
  p{
    font-size: 10px;
    font-weight: 300;
    color: #4a4a30;
    text-align: center;
  }
}
.nat-trav{
  color: #AB650D;
  font-weight: 300;
}
.back-btn{
  margin-top: -15px;
}
.pointer{
  cursor: pointer;
}
.select-lang{
  margin: 15px 16px 25px;
  width: calc(100% - 32px);
  text-align: left;
}
.no-p-top{
  padding-top: 0;
}
.company-infos{
  font-size: 16px;
  color: #CCC;
  display: inline-block;
}

.company_num_ass.company-infos{
  font-size: 24px;
  color:#4a4a30;
}

.table-bonds-fideris {
  td, th {
    padding: 0px 8px !important;
  }
  .v-data-table__mobile-row {
    &:nth-child(9) {
      display: none;
    }
  }
}

.monospace {
  font-family: 'Courier New', monospace !important;
}
.nowrap {
  white-space: nowrap !important;
}
.no-padding {
  padding: 0px !important;
}
.v-application .error--text {
  background-color: #fef0f0;
}
.dialog-position {
  margin: 10% auto !important;
}
</style>
