<template>
  <div>
    <v-list v-if="isConnected">
      <LanguageSelector />
      <v-list-item
        v-for="item in menuForThisRole"
        :key="item.title"
        :to="{ 'name': item.link }"
        exact
        >
        <v-list-item-action>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>{{ $t(item.title) }}</v-list-item-content>
      </v-list-item>
      <v-list-item class="logout-menu" @click="logout">
        <v-list-item-action>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>{{ $t('logout') }}</v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list dense v-else>
      <LanguageSelector />
      <v-list-item :to="{ name: 'login' }" exact>
        <v-list-item-action>
          <v-icon>mdi-login-variant</v-icon>
        </v-list-item-action>
        <v-list-item-content>{{ $t('login') }}</v-list-item-content>
      </v-list-item>
    </v-list>
    <div class="copyright">
      <p>Fideris™ Copyright© 2020</p>
    </div>
  </div>
</template>

<script>
import LanguageSelector from './Helpers/LanguageSelector'
export default {
  components: { LanguageSelector },
  data () {
    return {
      userItems: [
        { icon: 'mdi-home', title: 'home', link: 'home' },
        { icon: 'mdi-file-document', title: 'bonds_management', link: 'bond-management' }
      ],
      adminItems: [
        { icon: 'mdi-home', title: 'home', link: 'home' },
        { icon: 'mdi-file-document', title: 'bonds_management', link: 'bond-management' },
        { icon: 'mdi-account-supervisor-circle', title: 'collaborator_management', link: 'admin-users-list' }
      ],
      superAdminItems: [
        { icon: 'mdi-home', title: 'home', link: 'bond-management' },
        { icon: 'mdi-domain', title: 'companies_management', link: 'companies-list' },
        { icon: 'mdi-file-document', title: 'edocs_management', link: 'home' },
        { icon: 'mdi-account-supervisor-circle', title: 'collaborator_management', link: 'admin-users-list' },
        { icon: 'mdi-cash', title: 'title_liberation', link: 'liberation-table' }
      ]
    }
  },
  computed: {
    isConnected () {
      if (this.$store.getters.getToken) {
        return true
      }
      return false
    },
    menuForThisRole () {
      if (this.$store.getters.isSuperAdmin) {
        return this.superAdminItems
      }
      if (this.$store.getters.isAdmin) {
        return this.adminItems
      }
      return this.userItems
    }
  },
  filters: {
    toUpperCase (value) {
      return value.toUpperCase()
    }
  },
  methods: {
    logout () {
      this.$store.commit('logout')
      this.$store.commit('setShouldReloadData', true)
      this.$store.commit('setBonds', [])
      this.$store.commit('setEdocs', [])
      this.$store.commit('setCurrentCompany', null)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>

<style>
</style>
