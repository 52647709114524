<template>
<v-container
  class="px-7"
  fluid
  id="liberation-table"
  pt-20
  >
    <v-snackbar
      v-model="cautNotFound"
      :bottom="true"
      color="error"
      :timeout="3000"
      >
      {{ $t('caut_not_found') }}
    </v-snackbar>
    <v-row>
      <v-col cols="12">
        <v-btn :to="{ name: 'bond-management' }" class="back-btn" color="#AB650D" dark>
          <v-icon dark left>mdi-arrow-left</v-icon>{{$t ('back') }}
        </v-btn>
        <h1 class="text-center mb-2">{{ $t('title_liberation') }}</h1>
        <hr class="mb-3">
      </v-col>
    </v-row>
    <v-row v-if="!listCreationLoading">
      <v-col cols="12">
        <div class="details-fiche elevation-4" justify="center" align="center" >
          <v-form
            v-model="valid"
            @submit.prevent="validate"
            id="liberation-form"
            >
            <v-row>
              <v-col cols="4">
                <h3 class="text-center pt-5">{{ $t('list_number')}} <b>{{ liberationListId }}</b></h3>
              </v-col>
              <v-col cols="4">
                <v-select
                  :label="$t('type')"
                  v-model="selectedBondType"
                  :items="bondType"
                  item-text="text"
                  item-value="value"
                  @change="resetEntries"
                >
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  type="date"
                  v-if="selectedBondType === 'PU'"
                  v-model="publicListDate"
                  :rules="[v => !!v || $t('date_required')]"
                  :label="$t('date')"
                  class="form-date"
                ></v-text-field>
              </v-col>
              <v-col cols="1" v-if="selectedBondType === 'PU'">
                <v-tooltip
                  bottom
                  v-if="publicListDate && getTrimester() !== getTrimester(publicListDate)"
                  >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="warning"
                      v-on="on"
                      class="pt-6"
                    >
                      mdi-alert
                    </v-icon>
                  </template>
                  <span>{{ $t('date_not_in_trimester') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-simple-table>
              <template>
                <thead>
                  <tr>
                    <th class="text-left"></th>
                    <th class="text-left">{{ $t('acte') }}</th>
                    <th class="text-left">{{ $t('name') }}</th>
                    <th class="text-left">{{ $t('beneficiaire') }}</th>
                    <th class="text-right">{{ $t('montant_initial') }}</th>
                    <th class="text-right">{{ $t('before') }}</th>
                    <th class="text-right">{{ $t('amount_to_liberate') }}</th>
                    <th class="text-right">{{ $t('after') }}</th>
                    <th class="text-left">{{ $t('date') }}</th>
                    <th v-if="selectedBondType === 'PR'"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(entry, index) in entries" :key="index">
                    <td @click="removeLine(index)"><v-icon style="cursor:pointer;">mdi-close-circle</v-icon></td>
                    <td width="180px">
                      <v-text-field
                        v-model="entry.searchedNrCaut"
                        :label="$t('nr_acte')"
                        :loading="entry.isLoading"
                        class="bond-selection"
                        @change="loadLiberableBond(entry, index)"
                        autocomplete="off"
                        :ref="'caut' + index"
                        >
                      </v-text-field>
                    </td>
                    <td>{{ entry.bond && entry.bond.company.name }}</td>
                    <td>
                      <v-tooltip top color="primary" v-if="entry.bond" v-model="entry.showTooltip">
                        <template v-slot:activator="{ on }">
                          <v-btn text icon fab v-on="on">
                            <v-icon>mdi-tooltip-account</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ getBeneficiaire(entry.bond) }}</span>
                      </v-tooltip>
                    </td>
                    <td class="text-right">{{ entry.bond && entry.bond.montant_initial | formatPrice }}</td>
                    <td class="text-right">{{ entry.bond && entry.bond.montant_engage | formatPrice }}</td>
                    <td class="text-right" width="180px">
                      <v-text-field
                        type="number"
                        v-if="entry.bond"
                        v-model="entry.amount"
                        :rules="amountRules(entry.bond.montant_engage)"
                        :label="$t('amount')"
                        class="form-solde"
                        @change="autoLineIncrement"
                        autocomplete="off"
                        :ref="'amount' + index"
                      ></v-text-field>
                    </td>
                    <td class="text-right">{{ entry.bond && (entry.bond.montant_engage - entry.amount) | formatPrice }}</td>
                    <td>
                      <v-text-field
                        type="date"
                        v-if="entry.bond && selectedBondType === 'PR'"
                        v-model="entry.date"
                        :rules="[v => !!v || $t('date_required')]"
                        :label="$t('date')"
                        class="form-date"
                        @change="autoLineIncrement"
                      >
                      </v-text-field>
                      <v-text-field
                        type="date"
                        v-if="entry.bond && selectedBondType === 'PU'"
                        v-model="publicListDate"
                        :label="$t('date')"
                        class="form-date"
                        readonly
                        disabled
                      ></v-text-field>
                    </td>
                    <td v-if="selectedBondType === 'PR'">
                      <v-tooltip bottom v-if="entry.date && getTrimester() !== getTrimester(entry.date)">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="warning"
                            v-on="on"
                          >
                            mdi-alert
                          </v-icon>
                        </template>
                        <span>{{ $t('date_not_in_trimester') }}</span>
                      </v-tooltip>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                      <v-btn text @click="addLine" id="add-line">
                        {{ $t('add_line') }} <v-icon right dark>mdi-plus-thick</v-icon>
                      </v-btn>
                    </td>
                    <td class="text-right">{{ total | formatPrice}}</td>
                    <td colspan="2"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="d-flex justify-end" >
              <v-btn
                type="submit"
                color="success"
                class="mt-8"
                id="submit"
                :disabled="!valid || formattedEntries.length === 0"
                x-large
                :loading="loading"
                >
                {{ $t('validate') }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-col>
    </v-row>
    <v-skeleton-loader
      class="mx-auto"
      max-width="100%"
      type="card"
      v-else
    ></v-skeleton-loader>
  </v-container>
</template>
<script>
import Vue from 'vue'
export default {
  data () {
    return {
      listCreationLoading: false,
      liberationListId: null,
      isBondLoading: false,
      entries: [],
      valid: false,
      loading: false,
      selectedBondType: 'PU',
      publicListDate: null,
      searchedNrCaut: null,
      cautNotFound: false
    }
  },
  computed: {
    formattedEntries () {
      const entries = []
      let entry
      for (let i = 0; i < this.entries.length; ++i) {
        if (this.isCurrentEntryValid(this.entries[i])) {
          entry = {
            bondHash: this.entries[i].bond.hash,
            amount: this.entries[i].amount,
            list: this.liberationListId
          }
          if (this.selectedBondType === 'PR') {
            entry.date = this.entries[i].date
          } else {
            entry.date = this.publicListDate
          }
          entries.push(entry)
        }
      }
      return entries
    },
    bondType () {
      return [
        { text: this.$t('private'), value: 'PR' },
        { text: this.$t('public'), value: 'PU' }
      ]
    },
    total () {
      let total = 0
      for (let i = 0; i < this.entries.length; ++i) {
        if (this.entries[i].amount) {
          total += parseFloat(this.entries[i].amount)
        }
      }
      return total
    }
  },
  created () {
    this.createLiberationList()
  },
  methods: {
    loadLiberableBond (entry, index) {
      const isPublic = this.selectedBondType === 'PU' ? 1 : 0
      const nrCaut = entry.searchedNrCaut
      if (!nrCaut) {
        return
      }
      entry.isLoading = true
      this.$store.dispatch('loadLiberableBond', { isPublic, nrCaut })
        .then(response => {
          entry.isLoading = false
          entry.bond = response
          this.autoFillAmount(entry)
          this.manageTooltipDisplay(entry)
          Vue.nextTick(() => {
            this.selectNextAmountInput(index)
          })
          this.autoLineIncrement()
        })
        .catch(() => {
          entry.isLoading = false
          entry.bond = null
          entry.amount = null
          entry.date = null
          this.cautNotFound = true
          // We get the nr_caut input (with $refs from vue) to focus it
          this.$refs['caut' + index][0].$el.querySelector('input').focus()
        })
    },
    createLiberationList () {
      this.listCreationLoading = true
      this.$store.dispatch('createLiberationList')
        .then(response => {
          this.liberationListId = response.id
          this.listCreationLoading = false
          this.addLine()
        })
    },
    addLine () {
      this.entries.push({ searchedNrCaut: null, isLoading: false, bond: null, amount: null, date: null, showTooltip: false })
    },
    removeLine (index) {
      this.entries.splice(index, 1)
    },
    amountRules (maxValue) {
      return [
        v => !!v || this.$t('montant_required'),
        v => v > 0 || this.$t('montant_greater_than_0'),
        v => v <= maxValue || this.$t('amount_should_not_be_greater_than_solde')
      ]
    },
    isCurrentEntryValid (entry) {
      if (this.selectedBondType === 'PR') {
        if (entry.bond && entry.bond.hash && entry.amount && entry.date) {
          return true
        }
      } else if (this.selectedBondType === 'PU') {
        if (entry.bond && entry.bond.hash && entry.amount && this.publicListDate) {
          return true
        }
      }
      return false
    },
    autoLineIncrement () {
      const lastEntry = this.entries[this.entries.length - 1]
      if (lastEntry.amount || lastEntry.date) {
        this.addLine()
      }
    },
    autoFillAmount (entry) {
      if (entry.bond && entry.bond.montant_engage) {
        const bond = entry.bond
        if (bond.montant_engage === bond.montant_initial) {
          entry.amount = (bond.montant_initial / 2).toFixed(2)
        } else {
          entry.amount = bond.montant_engage
        }
      }
    },
    manageTooltipDisplay (entry) {
      for (let i = 0; i < this.entries.length; ++i) {
        this.entries[i].showTooltip = false
      }
      entry.showTooltip = true
    },
    selectNextAmountInput (index) {
      this.$refs['amount' + index][0].$el.querySelector('input').select()
    },
    validate () {
      if (this.valid) {
        this.loading = true
        this.$store.dispatch('createMultipleLiberation', this.formattedEntries)
          .then(response => {
            this.$store.commit('setSuccessAlert', this.$t('success_multiple_liberations'))
            this.loading = false
            this.resetEntries()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    resetEntries () {
      this.entries = []
      this.addLine()
    },
    getTrimester (askedDate = null) {
      let date
      if (askedDate) {
        date = new Date(askedDate)
      } else {
        date = new Date()
      }
      const month = date.getMonth()
      const year = date.getYear()
      let trimesterNr
      if ([0, 1, 2].includes(month)) {
        trimesterNr = 1
      } else if ([3, 4, 5].includes(month)) {
        trimesterNr = 2
      } else if ([6, 7, 8].includes(month)) {
        trimesterNr = 3
      } else if ([9, 10, 11].includes(month)) {
        trimesterNr = 4
      }
      return trimesterNr + '' + year
    },
    getBeneficiaire (bond) {
      if (!bond || !bond.beneficiaires[0]) {
        return this.$t('no_benef_found')
      }
      const b = bond.beneficiaires[0].beneficiaire
      const display = [b.name, ' | ', b.street, b.number, b.city, b.post_code, b.country]
      return display.join(' ')
    }
  }
}
</script>

<style lang="scss">
.form-solde input {
  text-align: right;
}
</style>
